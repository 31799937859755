import React, { useEffect, useState, useRef } from "react";
import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import ImgCompo from "components/img-compo";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const initialState = {
    preview: "",
    raw: "",
};

const EmployeeDetails = () => {
    const [validated, setValidated] = useState(false);
    const [cellNumberError, setCellNumberError] = useState("");
    const [streetName, setStreetName] = useState("");
    const [suburb, setSuburb] = useState("");
    const [town, setTown] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [employmentSector, setEmploymentSector] = useState("");
    const [employerName, setEmployerName] = useState("");
    const [businessTel, setBusinessTel] = useState("");
    const [employeeNo, setEmployeeNo] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [departmentUnit, setDepartmentUnit] = useState("");
    const [mainMemberData, setMainMemberData] = useState();
    const [mainID, setMainID] = useState(initialState);
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [approved, setApproved] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (localStorage.getItem("profile")) {
            const profile = JSON.parse(localStorage.getItem("profile"));
            setMainMemberData(profile);
        }

        if (localStorage.getItem("employeeDetails")) {
            const employeeDetails = JSON.parse(localStorage.getItem("employeeDetails"));
            if (employeeDetails.employmentSector) setEmploymentSector(employeeDetails.employmentSector);
            if (employeeDetails.employerName) setEmployerName(employeeDetails.employerName);
            if (employeeDetails.businessTel) setBusinessTel(employeeDetails.businessTel);
            if (employeeDetails.employeeNo) setEmployeeNo(employeeDetails.employeeNo);
            if (employeeDetails.jobTitle) setJobTitle(employeeDetails.jobTitle);
            if (employeeDetails.departmentUnit) setDepartmentUnit(employeeDetails.departmentUnit);
        }

        if (localStorage.getItem("profileAddress")) {
            const address = JSON.parse(localStorage.getItem("profileAddress"));
            setStreetName(address.streetName);
            setSuburb(address.suburb);
            setTown(address.town);
            setPostalCode(address.postalCode);
        }

        if (localStorage.getItem("MainId")) {
            const mainIDs = JSON.parse(localStorage.getItem("MainId"));
            setMainID({ preview: mainIDs.mainID });
        }
    }, []);

    const handleChange = (e) => {
        if (e.target.files.length) {
            setMainID({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };

    const handleChangeCellNo = (e,) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setBusinessTel(e.target.value);
        }
    };

    const handleClear = (e) => {
        e.preventDefault();
        inputRef.current.value = null;
        setMainID(initialState);
        localStorage.removeItem('MainId');
    };

    const checkCellNumber = (number) => {
        if (number.charAt(0) === "0") {
            if (number.length > 10) {
                setCellNumberError("Please give a valid Business phone number");
                return false;
            } else if (number.length < 10) {
                setCellNumberError("Please give a valid Business phone number");
                return false;
            } else {
                return true;
            }
        }
        if (number.charAt(0) === "2") {
            if (number.length > 11) {
                setCellNumberError("Please give a valid Business phone number");
                return false;
            } else if (number.length < 11) {
                setCellNumberError("Please give a valid Business phone number");
                return false;
            } else {
                return true;
            }
        }
    };

    useEffect(() => {
        if (approved) {
            setIsLoading(true);
            let representativeData; let profile; let selfCoverDatas; let extendedFamily; let totalCost; let employeeDetail; let MainId; let address; let indexPage;
            if (localStorage.getItem("selfCoverData")) selfCoverDatas = JSON.parse(localStorage.getItem("selfCoverData"));
            if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
            if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
            if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
            if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
            if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));
            if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
            if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
            if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));

            let payload = {
                page: 'employee-details',
                sourceData: "funeralApp",
                representativeData,
                profile,
                selfCoverDatas,
                extendedFamily,
                indexPage,
                totalCost,
                address,
                employeeDetail,
                MainId
            }

            var config = {
                method: "post",
                url: "https://apiv2.msgl.ink/",
                headers: {
                    "content-type": "application/json",
                },
                data: {
                    ...payload,
                },
            };
            axios(config)
                .then(function (response) {
                    setIsLoading(false);

                    navigate("/beneficiary");
                })
                .catch(function (error) {
                    setIsLoading(false);
                });
        }

    }, [approved, navigate])

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (streetName && suburb && town && postalCode && employerName) {
            if (mainID.preview) {
                const formData = new FormData();
                formData.append("image", mainID.raw);
                const path = document.getElementById("uploadImg");
                let data = { mainID: path.src }
                localStorage.setItem('MainId', JSON.stringify(data));
            }

            const employeeDetail = {
                employmentSector: employmentSector,
                employerName: employerName,
                businessTel: businessTel,
                employeeNo: employeeNo,
                jobTitle: jobTitle,
                departmentUnit: departmentUnit
            };

            const address = {
                streetName: streetName,
                suburb: suburb,
                town: town,
                postalCode: postalCode,
            };

            localStorage.setItem("employeeDetails", JSON.stringify(employeeDetail));
            localStorage.setItem("profileAddress", JSON.stringify(address));
            setValidated(false);
            if (businessTel && businessTel.length > 0) {
                if (checkCellNumber(businessTel)) {
                    setValidated(true);
                    setCellNumberError("");
                } else {
                    event.preventDefault();
                    setValidated(false);
                    setCellNumberError("Please give a valid business phone number");
                }
            }
        } else {
            event.preventDefault();
            setValidated(true);
        }
    };

    return (
        <Container className="h-100">
            {isLoading && <div className="loaderOverlay">
                <Spinner animation="border" role="status" variant="light" />

                <div>Submitting Data, please wait...</div>
            </div>}
            <div className="main-content">
                <h1 className="page-title mb-5">
                    ALMOST THERE JUST A FEW MORE DETAILS WE NEED
                </h1>

                <Form noValidate validated={validated}>
                    <div className="page-header">MAIN MEMBER</div>
                    <div className="m-1 mb-4">
                        <p className="text-sm">
                            {mainMemberData && mainMemberData.fullNames + ' ' + mainMemberData.surname}
                        </p>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} md="12" className="mt-0">
                                <Form.Label htmlFor="streetName">Address *</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-address.png"
                                            alt="Address"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Street Address"
                                        aria-describedby="inputGroupPrepend"
                                        value={streetName}
                                        onChange={(e) => setStreetName(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="valid">
                                        Street address is valid.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a Street address.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-address.png"
                                            alt="Address"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Suburb"
                                        aria-describedby="inputGroupPrepend"
                                        value={suburb}
                                        onChange={(e) => setSuburb(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="valid">
                                        Suburb is valid.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a Suburb.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} xs="6">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-address.png"
                                            alt="town"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="town"
                                        aria-describedby="inputGroupPrepend"
                                        value={town}
                                        onChange={(e) => setTown(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="valid">
                                        town is valid.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a town.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} xs="6">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-address.png"
                                            alt="Postal Code"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Postal Code"
                                        aria-describedby="inputGroupPrepend"
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="valid">
                                        Postal Code is valid.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a Postal Code.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </div>
                    <div className="page-header">UPLOAD ID</div>
                    <br /><br />
                    <div className="m-auto px-3 pb-3 d-flex flex-column  align-items-center justify-content-center">
                        <div className="mb-4">
                            <label htmlFor="upload-button" className="img-uploader-label">
                                {mainID.preview ? (
                                    <ImgCompo
                                        src={mainID.preview}
                                        alt="Uploaded ID"
                                        className="img-fluid"
                                        id="uploadImg"
                                    />
                                ) : (
                                    <ImgCompo
                                        src={"/images/icon-upload-id.png"}
                                        alt="Upload ID"
                                        height={128}
                                        width={128}
                                    />
                                )}
                            </label>
                            <input
                                type="file"
                                id="upload-button"
                                ref={inputRef}
                                hidden
                                accept="image/*"
                                onChange={handleChange}
                            />
                        </div>
                        {mainID.preview ? (
                            <ul className="btn-list">
                                <li>
                                    <Button
                                        onClick={handleClear}
                                        variant="outline-secondary"
                                        size="sm"
                                        className="fs-6"
                                    >
                                        Clear
                                    </Button>
                                </li>
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* {!mainID.preview ? <div className="text-center text-sm" style={{ color: "red" }}>
                        <p>Please Upload Main Member ID</p>
                    </div> : ''} */}

                    <div className="page-header">EMPLOYER DETAILS</div>
                    <div className="m-1">
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="employmentSector">Employment Sector</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-user.png"
                                            alt="employmentSector"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Select
                                        value={employmentSector}
                                        onChange={(e) => setEmploymentSector(e.target.value)}
                                    >
                                        <option value="">Choose Employment Sector</option>
                                        <option value="Private">Private</option>
                                        <option value="DOD">DOD</option>
                                        <option value="Government & SOE">Government & SOE</option>
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="employerName">Employer Name *</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-user.png"
                                            alt="employerName"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Employer Name"
                                        aria-describedby="inputGroupPrepend"
                                        value={employerName}
                                        onChange={(e) => setEmployerName(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="valid">
                                        Employer Name is valid.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a Employer Name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="businessTel">Business Tel</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-phone.png"
                                            alt="businessTel"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Business Tel"
                                        aria-describedby="inputGroupPrepend"
                                        value={businessTel}
                                        isInvalid={cellNumberError}
                                        maxLength="11"
                                        onChange={(e) => handleChangeCellNo(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {cellNumberError && cellNumberError}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="employeeNo">Force / Employee Number</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-user.png"
                                            alt="employeeNo"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Force/Employee number"
                                        aria-describedby="inputGroupPrepend"
                                        value={employeeNo}
                                        onChange={(e) => setEmployeeNo(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="jobTitle">Job Title</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-user.png"
                                            alt="jobTitle"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Job Title"
                                        aria-describedby="inputGroupPrepend"
                                        value={jobTitle}
                                        onChange={(e) => setJobTitle(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label htmlFor="departmentUnit">Department / Unit</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <ImgCompo
                                            src="/images/icon-user.png"
                                            alt="departmentUnit"
                                            width={16}
                                            height={16}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Department / Unit"
                                        aria-describedby="inputGroupPrepend"
                                        value={departmentUnit}
                                        onChange={(e) => setDepartmentUnit(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </div>

                    <Footer>
                        <ul className="btn-list">
                            <li className="back-li">
                                <BtnCompo
                                    buttonAction={"/quote-summary"}
                                    buttonIcon={"/images/icon-back.png"}
                                    buttonClass={"btn-back"}
                                    buttonIconHeight={22}
                                    buttonIconWidth={27}
                                />
                            </li>
                            <li>
                                <BtnCompo
                                    buttonAction={"/rather-call-me"}
                                    buttonText={"RATHER CALL ME"}
                                    buttonOutline={true}
                                    buttonClass={"btn-rather"}
                                />
                            </li>
                            <li>
                                <Button variant="primary" onClick={(e) => { handleSubmit(e); setApproved(true) }} disabled={!streetName || !suburb || !town || !postalCode || !employerName}>
                                    NEXT
                                </Button>
                            </li>
                        </ul>
                    </Footer>
                </Form>
            </div>
        </Container>
    );
};

export default EmployeeDetails;
