import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import jsPdf from "jspdf";
import ShieldLogo from '../../src/images/logo.png';
import FinancialService from '../../src/images/MF_Financial_Services.jpeg';
import axios from "axios";
import { Spinner } from "react-bootstrap";

const Document = () => {
  const [approved, setApproved] = useState(false);
  const [profile, setProfile] = useState();
  const [profileAddress, setProfileAddress] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [beneficiary, setBeneficiary] = useState();
  const [addInfo, setAddInfo] = useState();
  const [checkInfoData, setCheckInfoData] = useState();
  const [totalCost, setTotalCost] = useState();
  const [bankingDetailsDO, setBankingDetailsDO] = useState();
  const [mainId, setMainId] = useState();
  const [selfCoverData, setSelfCoverData] = useState();
  const [extendedFamily, setExtendedFamily] = useState([]);
  const [spouceCover, setSpouseCover] = useState([]);
  const [memberCover, setMemberCover] = useState([]);
  const [signature, setSignature] = useState();
  const [base64Data, setBase64Data] = useState();
  const [representativeData, setRepresentativeData] = useState();
  const [isLoading, setIsLoading] = useState();

  const date = Date.now();
  const d = new Date(parseInt(date, 10));
  const timeStamp = d.toString("MM/dd/yy HH:mm:ss");
  const now = new Date();
  let day = now.getDate();
  let monthNo = now.getMonth() + 1;
  let yearNow = now.getFullYear();

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      let profile = JSON.parse(localStorage.getItem("profile"));
      setProfile(profile);
    }

    if (localStorage.getItem("profileAddress")) {
      let address = JSON.parse(localStorage.getItem("profileAddress"));
      setProfileAddress(address);
    }

    if (localStorage.getItem("employeeDetails")) {
      let employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      setEmployeeDetails(employeeDetail);
    }

    if (localStorage.getItem("beneficiary")) {
      const beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      setBeneficiary(beneficiary);
    }

    if (localStorage.getItem("addInfo")) {
      let addInfo = JSON.parse(localStorage.getItem("addInfo"));
      setAddInfo(addInfo);
    }

    if (localStorage.getItem("checkInfoData")) {
      let checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      setCheckInfoData(checkInfoData);
    }

    if (localStorage.getItem("totalCost")) {
      let totalCost = JSON.parse(localStorage.getItem("totalCost"));
      setTotalCost(totalCost);
    }

    if (localStorage.getItem("representativeData")) {
      let representativeDatas = JSON.parse(localStorage.getItem("representativeData"));
      setRepresentativeData(representativeDatas);
    }

    if (localStorage.getItem("bankingDetailsDO")) {
      let bankingDetailsDO = JSON.parse(
        localStorage.getItem("bankingDetailsDO")
      );
      setBankingDetailsDO(bankingDetailsDO);
    }

    if (localStorage.getItem("MainId")) {
      let mainId = JSON.parse(localStorage.getItem("MainId"));
      setMainId(mainId);
    }

    if (localStorage.getItem("selfCoverData")) {
      let selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));
      setSelfCoverData(selfCoverData);
    }

    if (localStorage.getItem("extendedFamily")) {
      let extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      setExtendedFamily(extendedFamily);
      let spouseData = extendedFamily.filter(item => item.memberRelation === 'spouse');
      let memberData = extendedFamily.filter(item => item.memberRelation !== 'spouse');
      setSpouseCover(spouseData); setMemberCover(memberData);
    }

    if (localStorage.getItem("signature")) {
      let signature = JSON.parse(localStorage.getItem("signature"));
      setSignature(signature.signature);
    }
  }, []);

  const pdfPayrollDeductionScript = async (type) => {
    let extendedFamilyArray = [];
    let extendedFamilyObj = {};

    if (extendedFamily.length > 0) {
      extendedFamily.forEach((item, index) => {
        if (item.memberRelation !== 'spouse') {
          extendedFamilyArray.push({
            ['id' + index]: index,
            ['memberRelation' + index]: item.memberRelation,
            ['relationship' + index]: item.relationship,
            ['name' + index]: item.name,
            ['surname' + index]: item.surname,
            ['dateOfBirth' + index]: item.dateOfBirth,
            ['age' + index]: item.age,
            ['gender' + index]: item.gender,
            ['benefit' + index]: item.benefit,
            ['premium' + index]: item.premium,
          });
        } else {
          extendedFamilyArray.push({
            ['id' + index]: index,
            ['memberRelation' + index]: item.memberRelation,
            ['relationship' + index]: item.relationship,
            ['name' + index]: item.name,
            ['surname' + index]: item.surname,
            ['dateOfBirth' + index]: item.dateOfBirth,
            ['age' + index]: item.age,
            ['gender' + index]: item.gender,
            ['benefit' + index]: item.benefit,
            ['premium' + index]: item.premium,
            ['spouseAccidentalAmount' + index]: item.spouseAccidentalAmount,
            ['spouseAccidentalCost' + index]: item.spouseAccidentalCost,
            ['spouseIncomeAmount' + index]: item.spouseIncomeAmount,
            ['spouseIncomeCost' + index]: item.spouseIncomeCost,
            ['spouseIllnessBenefits' + index]: item.spouseIllnessBenefits,
            ['spouseTotalCost' + index]: item.spouseTotalCost,
          });
        }
      });

      for (let i = 0; i < extendedFamilyArray.length; i++) {
        Object.assign(extendedFamilyObj, extendedFamilyArray[i]);
      }
    }

    var doc = new jsPdf({ compress: true });

    var pageline = 20;
    let code;
    if (representativeData && representativeData.representativeCode) code = representativeData.representativeCode.toLowerCase();
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.setFontSize(9);
    doc.text('Shield Life - FSP 47477', 5, 5);
    doc.addImage(ShieldLogo, "PNG", 10, 5, 25, 25, "logo", "MEDIUM");
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text('Maxi Forces Financial Services - FSP 1966', 145, 5);
      doc.addImage(FinancialService, "JPEG", 170, 8, 30, 20, "finaceLogo", "MEDIUM");
    }
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("#444");
    doc.setFontSize(16);
    doc.text("Individual Funeral Cover", 75, pageline + 15);
    doc.text("Application Form", 85, pageline + 25);
    if (representativeData) {
      doc.setFontSize(10);
      doc.text("Representative Code:", 145, pageline + 25);
      doc.setFont("Helvetica", "normal");
      doc.text(representativeData.representativeCode, 182, pageline + 25);
    }
    doc.setFontSize(9);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("red");
    doc.text("Please Note:", 15, pageline + 35);
    doc.setTextColor("#444");
    doc.text("In terms of the Financial Intelligence Centre Amendment Act 1 of 2017 and to improve the on-boarding process, we", 15, pageline + 40);
    doc.text("require a copy of the following documentation to accompany this application:", 15, pageline + 45);
    doc.setFont("Helvetica", "normal");
    doc.text("Identification of the applicant (ID book/card or Driver License)", 20, pageline + 50);
    // doc.text("Proof of banking details not older than 3 months (if Debit Order)", 20, pageline + 55);
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("To escalate the claim process you can also supply the following documentation (where applicable), however if not", 15, pageline + 60);
    doc.text("available, it must be submitted on claims stage:", 15, pageline + 65);
    doc.setFont("Helvetica", "bold");
    if (beneficiary) doc.text(beneficiary.beneficiaryIDNo, 135, pageline + 70);
    doc.setFont("Helvetica", "normal");
    doc.text("Identification of the nominated beneficiary/ies (ID book/card or Driver License);", 20, pageline + 70);
    doc.text("Identification of each spouse covered under this application (ID book/card or Driver License);", 20, pageline + 75);
    doc.text("Marriage Certificate or equivalent;", 20, pageline + 80);
    doc.text("Identification of each dependent child covered under this application (ID book/card or Birth Certificate);", 20, pageline + 85);
    doc.text("Identification of each extended family member covered under this application (ID book/card; Driver License or Birth", 20, pageline + 90);
    doc.text("Certificate)", 20, pageline + 95);

    pageline = 20;
    //POPI & CONSENT
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text("1. Legislation information and requirements:", 15, pageline + 105);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    doc.text("This policy is governed by South African Law and any legal action in relation to this policy is subject to the jurisdiction of the ", 15, pageline + 110);
    doc.text("South African Courts.", 15, pageline + 115);

    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("1.1 Personal Information", 15, pageline + 120);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");
    doc.text("In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the processing of", 15, pageline + 125);
    doc.text("Personal Information. When entering into this policy you provide us with information that may be protected by legislation includ", 15, pageline + 130);
    doc.text("ing but not limited to PoPIA. We will take all reasonable steps to protect the information you provide us securely and confident", 15, pageline + 135);
    doc.text("ially. Please read more how we use and protect your personal information in our Privacy Notice and PAIA manual available on", 15, pageline + 140);
    doc.text("our website https://www.shieldlife.co.za.", 15, pageline + 145);
    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    // pageline = pageline - 5;
    doc.text("1.2 Sharing of Insurance Information", 15, pageline + 150);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.text("The South African Regulatory bodies require insurers to share information with the regulatory body regarding policies and claim", 15, pageline + 155);
    doc.text("In addition, insurers share information with each other regarding policies and claims with the view to prevent fraudulent claims", 15, pageline + 160);
    doc.text("and obtain material information regarding the assessment of risks proposed for insurance. By reducing the incidents of fraud", 15, pageline + 165);
    doc.text("and assessing risks fairly, future premium increases may be limited.  This is done in the public interest and in the interest of all", 15, pageline + 170);
    doc.text("current and potential policyholders.", 15, pageline + 175);
    doc.text("By the insurer accepting or renewing this insurance, you or any other person that is represented herein, gives consent to the", 15, pageline + 180);
    doc.text("said information being disclosed to any other insurance company or its agent.You also similarly give consent to the sharing of ", 15, pageline + 185);
    doc.text("information in regards to past insurance policies and claims that you have made.You also acknowledge that information provid", 15, pageline + 190);
    doc.text("ed by yourself or your representative may be verified against any legally recognised sources or databases.", 15, pageline + 195);
    doc.text("By insuring or renewing your insurance you hereby not only consent to such information sharing, but also waive any rights of", 15, pageline + 200);
    doc.text("confidentiality with regards to underwriting or claims information that you have provided or that has been provided by another", 15, pageline + 205);
    doc.text("person on your behalf. In the event of a claim, the information you have supplied with your application, together with the inform", 15, pageline + 210);
    doc.text("ation you supply in relation to the claim, will be made available to other insurers participating in the sharing of insurance inform", 15, pageline + 215);
    doc.text("ation. As a member of Shield Life's funeral plan, you, your dependants and extended family's personal information may be sha", 15, pageline + 220);
    doc.text("red with the employer. This will be limited to information that is relevant to you and/or your dependants’ and/or your extended's", 15, pageline + 225);
    doc.text("application or information that is required for the ongoing servicing of your funeral plan.", 15, pageline + 230);

    pageline = 15;
    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("1.3 Client Due Diligence", 15, pageline + 240);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.text("As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre Amendment Act 1 of", 15, pageline + 245);
    doc.text("2017, we are required to gather the following member information prior to concluding the transaction.", 15, pageline + 250);
    doc.text("The nature and purpose of this transaction is to secure Group Life and Funeral cover.", 15, pageline + 255);
    doc.text("What is the source of Funds?", 15, pageline + 260);
    doc.setFont("Helvetica", "bold");
    if (checkInfoData) doc.text(checkInfoData.sourceIncome, 70, pageline + 260);
    doc.text("If Other, specify:", 100, pageline + 260);
    doc.setFont("Helvetica", "normal");
    if (checkInfoData) doc.text(checkInfoData.sourceIncomeDetails, 130, pageline + 260);
    doc.text("____________________________", 130, pageline + 262)

    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("1.4 Consent and declaration", 15, pageline + 267);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");
    doc.text("I, the Policy Holder of this policy, hereby give consent to the Processing of my Personal Information in terms of POPIA for all", 15, pageline + 272);
    doc.text("purposes of maintaining this insurance product and consent to the terms of the Privacy Notice and the PAIA manual located on", 15, pageline + 277);

    doc.addPage();
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");
    pageline = 10;
    doc.text("our website https://www.shieldlife.co.za. I understand that the supply of this information is mandatory and without the supply of", 15, pageline);
    doc.text("the information this agreement will be will be invalid. Shield Life will process and protect my personal information in terms of the", 15, pageline + 5);
    doc.text("required aspects of POPIA.", 15, pageline + 10);
    pageline = pageline + 5;
    doc.text("I hereby give consent that Shield Life may request further processing of my personal information from a third party (Premium", 15, pageline + 10);
    doc.text("collection agency, Credit bureau etc.) for the purposes of maintaining my insurance product. I hereby agree that I have the appr", 15, pageline + 15);
    doc.text("opriate authorization of supplying special / sensitive / personal information of data subjects other than myself.", 15, pageline + 20);
    doc.text("I hereby agree that all the information supplied in this agreement is accurate and complete and should any of this information", 15, pageline + 25);
    doc.text("change I will contact Shield Life Ltd to update my personal information.", 15, pageline + 30);
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("I hereby consent that my personal information may be used for marketing purposes with the Maxi Forces Financial Services", 15, pageline + 35);
      doc.text("and Shield Life Insurance Product range.", 15, pageline + 40);
    } else {
      doc.text("I hereby consent that my personal information may be used for marketing purposes within the Shield Life Insurance Product", 15, pageline + 35);
      doc.text("range.", 15, pageline + 40);
    }
    doc.setFont("Helvetica", "bold");
    doc.setFillColor("4068a1");
    if (addInfo && addInfo.sendInfo === "yes") doc.rect(160, pageline + 37, 4, 4, "FD");
    else doc.rect(160, pageline + 37, 4, 4);
    doc.text('Yes', 165, pageline + 40);
    doc.setFillColor("4068a1");
    if (addInfo && addInfo.sendInfo === "no") doc.rect(175, pageline + 37, 4, 4, "FD");
    else doc.rect(175, pageline + 37, 4, 4);
    doc.text('No', 180, pageline + 40);
    doc.setFont("Helvetica", "normal");
    doc.text("I, the undersigned, understand that without the above consent my policy will not be processed and Shield Life will not be liable", 15, pageline + 45);
    doc.text("for any claim that may arise. I understand that the cover will only start when my application has been accepted and I have paid", 15, pageline + 50);
    doc.text("the first premium. I authorise the Insurer to communicate with me regarding my policy via Short Message System (SMS); Whats", 15, pageline + 55);
    doc.text("App; and/or email. I, the undersigned, declare that the source of the funds that I expect to use in concluding transactions with", 15, pageline + 60);
    doc.text("Shield Life® Ltd originates from the sources indicated above. I further confirm that these funds are derived from legitimate sources.", 15, pageline + 65);

    if (signature) doc.addImage(signature, "JPEG", 25, pageline + 55, 35, 35, "final", "MEDIUM");
    doc.text("____________________________", 20, pageline + 80);
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("Signature of Main Member", 25, pageline + 85);
    if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 72, pageline + 75);
    doc.text(`Date: ${timeStamp}`, 72, pageline + 80);

    // PERSONAL INFORMATION
    // doc.addPage();
    pageline = 10;
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text("2. Compulsory personal information of Main Insured Life:", 15, pageline + 100);
    doc.setFontSize(9);
    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("2.1. General Details:", 15, pageline + 105);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "bold");
    doc.text("Rank / Title:", 15, pageline + 111);
    doc.rect(45, pageline + 107, 55, 5, "S");
    doc.text("Full Names:", 15, pageline + 116);
    doc.rect(45, pageline + 112, 55, 5, "S");
    doc.text("ID Number:", 102, pageline + 111);
    doc.rect(135, pageline + 107, 60, 5, "S");
    doc.text("Surname:", 102, pageline + 116);
    doc.rect(135, pageline + 112, 60, 5, "S");
    doc.setFont("Helvetica", "normal");
    if (profile) {
      if (profile.rankTitle) doc.text(profile.rankTitle, 48, pageline + 111);
      doc.text(profile.fullNames, 48, pageline + 116);
      if (profile.IDNumber) doc.text(profile.IDNumber, 138, pageline + 111);
      doc.text(profile.surname, 138, pageline + 116);
    }

    // doc.text("Marriage Status:", 15, pageline + 121);
    // doc.setFont("Helvetica", "normal");
    // doc.rect(45, pageline + 120, 4, 4);
    // doc.text('Single', 51, pageline + 123);
    // doc.rect(75, pageline + 120, 4, 4);
    // doc.text('Married', 81, pageline + 123);
    // doc.rect(105, pageline + 120, 4, 4);
    // doc.text('Divorced', 111, pageline + 123);
    // doc.rect(135, pageline + 120, 4, 4);
    // doc.text('Widowed', 141, pageline + 123);
    // doc.rect(165, pageline + 120, 4, 4);
    // doc.text('Single with children', 171, pageline + 123);

    pageline = pageline - 9;
    doc.setFont("Helvetica", "bold");
    doc.text("Cell Number:", 15, pageline + 130);
    doc.rect(45, pageline + 126, 55, 5, "S");
    doc.text("Email:", 15, pageline + 135);
    doc.rect(45, pageline + 131, 55, 5, "S");

    doc.text("Alternative Number:", 102, pageline + 130);
    doc.rect(135, pageline + 126, 60, 5, "S");
    doc.setFont("Helvetica", "normal");
    if (profile) {
      doc.text(profile.cellNumber, 48, pageline + 130);
      doc.text(profile.alternativeNo, 138, pageline + 130);
      doc.text(profile.emailAddress, 48, pageline + 135);
    }

    pageline = pageline + 10;
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(9);
    doc.setFillColor("4068a1");
    doc.rect(15, pageline + 130, 40, 5, "FD");
    doc.rect(55, pageline + 130, 50, 5, "FD");
    doc.rect(105, pageline + 130, 30, 5, "FD");
    doc.rect(135, pageline + 130, 30, 5, "FD");
    doc.rect(165, pageline + 130, 30, 5, "FD");

    doc.setTextColor(255, 255, 255);
    doc.text("Address", 28, pageline + 134);
    doc.text("Street and/or Number", 65, pageline + 134);
    doc.text("Suburb", 115, pageline + 134);
    doc.text("City", 147, pageline + 134);
    doc.text("Code", 176, pageline + 134);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");
    doc.rect(15, pageline + 135, 40, 5, "S");
    doc.text("Physical Address", 16, pageline + 139);
    // doc.rect(15, pageline + 140, 40, 5, "S");
    // doc.text("Postal Address", 16, pageline + 144);

    doc.rect(55, pageline + 135, 50, 5, "S");
    doc.rect(105, pageline + 135, 30, 5, "S");
    doc.rect(135, pageline + 135, 30, 5, "S");
    doc.rect(165, pageline + 135, 30, 5, "S");
    if (profileAddress) {
      doc.text(profileAddress.streetName, 58, pageline + 139);
      doc.text(profileAddress.suburb, 108, pageline + 139);
      doc.text(profileAddress.town, 138, pageline + 139);
      doc.text(profileAddress.postalCode, 168, pageline + 139);
    }

    pageline = pageline + 12;
    doc.setFont("Helvetica", "bold");
    doc.text("Contact method:", 15, pageline + 157);
    doc.setFont("Helvetica", "normal");
    doc.setFillColor("4068a1");
    if (profile && profile.contactMethod.email) doc.rect(45, pageline + 154, 4, 4, "FD");
    else doc.rect(45, pageline + 154, 4, 4);
    doc.text('Email', 51, pageline + 157);
    doc.setFillColor("4068a1");
    if (profile && profile.contactMethod.sms) doc.rect(75, pageline + 154, 4, 4, "FD");
    else doc.rect(75, pageline + 154, 4, 4);
    doc.text('SMS', 81, pageline + 157);
    doc.setFillColor("4068a1");
    if (profile && profile.contactMethod.cellphone) doc.rect(105, pageline + 154, 4, 4, "FD");
    else doc.rect(105, pageline + 154, 4, 4);;
    doc.text('Cellphone', 111, pageline + 157);

    pageline = -10;
    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("2.2. Employer Details:", 15, pageline + 168);
    doc.setTextColor("#444");
    doc.text("Employment sector:", 15, pageline + 173);
    doc.setFont("Helvetica", "normal");
    doc.setFillColor("4068a1");
    if (employeeDetails && employeeDetails.employmentSector === 'Private') doc.rect(50, pageline + 170, 4, 4, "FD");
    else doc.rect(50, pageline + 170, 4, 4);
    doc.text('Private', 55, pageline + 173);
    doc.setFillColor("4068a1");
    if (employeeDetails && employeeDetails.employmentSector === 'DOD') doc.rect(75, pageline + 170, 4, 4, "FD");
    else doc.rect(75, pageline + 170, 4, 4);
    doc.text('DOD', 81, pageline + 173);
    doc.setFillColor("4068a1");
    if (employeeDetails && employeeDetails.employmentSector === 'Government & SOE') doc.rect(105, pageline + 170, 4, 4, "FD");
    else doc.rect(105, pageline + 170, 4, 4);
    doc.text('Government & SOE', 111, pageline + 173);

    doc.setFont("Helvetica", "bold");
    doc.text("Name of Employer:", 15, pageline + 178);
    doc.text("Force / Employee No:", 15, pageline + 183);
    doc.rect(50, pageline + 175, 55, 5, "S");
    doc.rect(50, pageline + 180, 55, 5, "S");
    doc.text("Business Tel:", 107, pageline + 178);
    doc.text("Job Title:", 107, pageline + 183);
    doc.rect(135, pageline + 175, 60, 5, "S");
    doc.rect(135, pageline + 180, 60, 5, "S");

    doc.setFont("Helvetica", "normal");
    if (employeeDetails) {
      doc.text(employeeDetails.employerName, 53, pageline + 179);
      doc.text(employeeDetails.employeeNo, 53, pageline + 184);
      doc.text(employeeDetails.businessTel, 138, pageline + 179);
      doc.text(employeeDetails.jobTitle, 138, pageline + 184);
    }

    // BENEFICIARY INFORMATION
    pageline = pageline + 2;
    doc.setTextColor("4068a1")
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("2.3 Details of Beneficiary:", 15, pageline + 195);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("#444");
    doc.text("A Beneficiary must be an adult over the age of 18. It is the person responsible for the funeral", 15, pageline + 200);
    pageline = pageline - 3;
    doc.setFont("Helvetica", "bold");
    doc.setFillColor("4068a1");
    doc.rect(15, pageline + 205, 40, 5, "FD");
    doc.rect(55, pageline + 205, 50, 5, "FD");
    doc.rect(105, pageline + 205, 30, 5, "FD");
    doc.rect(135, pageline + 205, 30, 5, "FD");
    doc.rect(165, pageline + 205, 30, 5, "FD");

    doc.setTextColor(255, 255, 255);
    doc.text("Beneficiary", 25, pageline + 209);
    doc.text("Full name & surname", 65, pageline + 209);
    doc.text("ID number", 112, pageline + 209);
    doc.text("Contact number", 138, pageline + 209);
    doc.text("Relationship", 170, pageline + 209);

    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.rect(15, pageline + 210, 40, 5, "S");
    doc.text("Primary", 16, pageline + 214);
    doc.rect(15, pageline + 215, 40, 5, "S");
    doc.text("Secondary", 16, pageline + 219);

    doc.rect(55, pageline + 210, 50, 5, "S");
    doc.rect(105, pageline + 210, 30, 5, "S");
    doc.rect(135, pageline + 210, 30, 5, "S");
    doc.rect(165, pageline + 210, 30, 5, "S");
    doc.rect(55, pageline + 215, 50, 5, "S");
    doc.rect(105, pageline + 215, 30, 5, "S");
    doc.rect(135, pageline + 215, 30, 5, "S");
    doc.rect(165, pageline + 215, 30, 5, "S");
    if (beneficiary) {
      doc.text(beneficiary.beneficiaryName + ' ' + beneficiary.beneficiarySurname, 58, pageline + 214);
      doc.text(beneficiary.beneficiaryIDNo, 108, pageline + 214);
      doc.text(beneficiary.beneficiaryCellNumber, 138, pageline + 214);
      doc.text(beneficiary.beneficiaryRelationship, 168, pageline + 214);
      doc.text(beneficiary.beneficiarySecondaryName + ' ' + beneficiary.beneficiarySecondarySurname, 58, pageline + 219);
      doc.text(beneficiary.beneficiarySecondaryIDNo, 108, pageline + 219);
      doc.text(beneficiary.beneficiarySecondaryCellNumber, 138, pageline + 219);
      doc.text(beneficiary.beneficiarySecondaryRelationship, 168, pageline + 219);
    }
    doc.setFont("Helvetica", "italic");
    doc.text("Please note: The next-in-line-beneficiary will only receive the benefit payment if the primary beneficiary predeceased", 15, pageline + 225);

    pageline = pageline + 235;
    doc.setFont("Helvetica", "Bold");
    doc.setFontSize(14);
    doc.text("3. Personal information of Additional Insured Life:", 15, pageline);
    doc.setTextColor("4068a1");
    doc.setFontSize(9);
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("3.1 Details of Immediate Family:", 15, pageline + 5);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    doc.text("You can add Three (3) spouses and up to Five (5) own dependent children under the age of 21 years as immediate family.", 15, pageline + 10);
    doc.text("Spouse means the person to whom you are legally married under the law of South Africa (including a civil, customary or", 15, pageline + 15);
    doc.text("same-sex marriage) or the person you have been living with for at least six (6) months in a relationship that is similar to marriage,", 15, pageline + 20);
    doc.text("and intended to be permanent like a marriage", 15, pageline + 25);
    doc.text("Own dependent children means, biological, stepchildren or legally-adopted children.", 15, pageline + 30);
    pageline = pageline + 25;
    doc.setFillColor("4068a1");
    doc.rect(15, pageline + 8, 30, 5, "FD");
    doc.rect(45, pageline + 8, 50, 5, "FD");
    doc.rect(95, pageline + 8, 30, 5, "FD");
    doc.rect(125, pageline + 8, 40, 5, "FD");
    doc.rect(165, pageline + 8, 30, 5, "FD");

    doc.setFont("Helvetica", "bold");
    doc.setTextColor(255, 255, 255);
    doc.text("Relationship", 20, pageline + 12);
    doc.text("Full name & surname", 55, pageline + 12);
    doc.text("ID no/Date of Birth", 96, pageline + 12);
    doc.text("Gender", 140, pageline + 12);
    doc.text("Age", 175, pageline + 12);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");

    let rectLine = pageline + 8;
    let textLine = pageline + 12;

    if (spouceCover.length > 0) {
      for (let s = 0; s < spouceCover.length; s++) {
        rectLine = rectLine + 5;
        textLine = textLine + 5;
        doc.rect(15, rectLine, 30, 5, "S");
        doc.text("Spouse", 17, textLine);
        doc.rect(45, rectLine, 50, 5, "S");
        doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 47, textLine);
        doc.rect(95, rectLine, 30, 5, "S");
        doc.text(spouceCover[s].dateOfBirth, 100, textLine);
        doc.rect(125, rectLine, 40, 5, "S");
        doc.setFillColor("4068a1");
        if (spouceCover[s].gender === "male") doc.rect(131, rectLine + 1, 3, 3, "FD");
        else doc.rect(131, rectLine + 1, 3, 3);
        doc.text('Male', 135, rectLine + 4);
        doc.setFillColor("4068a1");
        if (spouceCover[s].gender === "female") doc.rect(146, rectLine + 1, 3, 3, "FD");
        else doc.rect(146, rectLine + 1, 3, 3);
        doc.text('Female', 150, rectLine + 4);
        doc.rect(165, rectLine, 30, 5, "S");
        doc.text(String(spouceCover[s].age), 176, textLine);
        pageline = textLine;
      }
    }
    // EXTENDED FAMILY
    doc.addPage();
    pageline = 0;
    doc.setTextColor("4068a1");
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("3.2 Details of Extended Family:", 15, pageline + 10);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor("#444");
    // doc.text("You can add ???? as extended family 6 members under the age of 75 years.", 15, pageline + 15);
    pageline = pageline - 5
    doc.text("8 Extended family means any person in whom you have an insurable interest.", 15, pageline + 20);
    pageline = pageline - 3;
    doc.setFillColor("4068a1");
    doc.rect(15, pageline + 25, 30, 5, "FD");
    doc.rect(45, pageline + 25, 50, 5, "FD");
    doc.rect(95, pageline + 25, 30, 5, "FD");
    doc.rect(125, pageline + 25, 40, 5, "FD");
    doc.rect(165, pageline + 25, 30, 5, "FD");
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(255, 255, 255);
    doc.text("Relationship", 20, pageline + 29);
    doc.text("Full name & surname", 55, pageline + 29);
    doc.text("ID no/Date of Birth", 96, pageline + 29);
    doc.text("Sub Relationship", 132, pageline + 29);
    doc.text("Age", 175, pageline + 29);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");

    let rectLines = pageline + 25;
    let textLines = pageline + 29;

    if (memberCover.length > 0) {
      for (let s = 0; s < memberCover.length; s++) {
        rectLines = rectLines + 5;
        textLines = textLines + 5;
        doc.rect(15, rectLines, 30, 5, "S");
        if (memberCover[s].memberRelation === 'ownChildren') doc.text("Own Children", 17, textLines);
        if (memberCover[s].memberRelation === 'parents') doc.text("Parents", 17, textLines);
        if (memberCover[s].memberRelation === 'formerSpouse') doc.text("Former Spouse", 17, textLines);
        if (memberCover[s].memberRelation === 'widerFamily') doc.text("Wider Family", 17, textLines);
        doc.rect(45, rectLines, 50, 5, "S");
        doc.text(memberCover[s].name + ' ' + memberCover[s].surname, 47, textLines);
        doc.rect(95, rectLines, 30, 5, "S");
        doc.text(memberCover[s].dateOfBirth, 100, textLines);
        doc.rect(125, rectLines, 40, 5, "S");
        doc.text(memberCover[s].relationship, 130, rectLines + 4);
        doc.rect(165, rectLines, 30, 5, "S");
        doc.text(String(memberCover[s].age), 176, textLines);

      }
    }

    // Benefit & Premium
    pageline = textLines;
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);
    doc.text("4. Benefit & Deduction details:", 15, pageline + 15)
    doc.setTextColor("4068a1");
    doc.setFontSize(9);
    doc.setFont("Helvetica", "italic", "bold");
    doc.text("4.1 Calculation of contribution:", 15, pageline + 20);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");

    pageline = pageline - 38;

    //Main Member Table
    doc.setFillColor("4068a1");
    doc.rect(15, pageline + 60, 40, 5, "FD");
    doc.rect(55, pageline + 60, 40, 5, "FD");
    doc.rect(95, pageline + 60, 40, 5, "FD");
    doc.rect(135, pageline + 60, 30, 5, "FD");
    doc.rect(165, pageline + 60, 30, 5, "FD");

    doc.setFont("Helvetica", "bold");
    doc.setTextColor(255, 255, 255);
    doc.text("Main Member", 25, pageline + 64);
    doc.text("Date of Birth", 65, pageline + 64);
    doc.text("Cover Type", 105, pageline + 64);
    doc.text("Cover Amount", 140, pageline + 64);
    doc.text("Premium", 173, pageline + 64);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "normal");

    let reactlines = pageline + 60;
    let textline = pageline + 64;
    pageline = textline;

    if (profile && selfCoverData?.funeralCost > 0) {
      doc.rect(15, reactlines + 5, 40, 5, "S");
      doc.text(`${profile?.fullNames} ${profile?.surname}`, 18, textline + 5);
      doc.rect(55, reactlines + 5, 40, 5, "S");
      doc.text(`${profile?.mainDOB}`, 65, textline + 5);
      doc.rect(95, reactlines + 5, 40, 5, "S");
      doc.text('Funeral', 106, textline + 5);
      doc.rect(135, reactlines + 5, 30, 5, "S");
      doc.text(`R${selfCoverData?.funeralAmount}`, 145, textline + 5);
      doc.rect(165, reactlines + 5, 30, 5, "S");
      doc.text(`R${selfCoverData?.funeralCost.toFixed(2)}`, 173, textline + 5);
      pageline = textline + 10;
    }

    if (profile && selfCoverData?.accidentalCost > 0) {
      doc.rect(15, reactlines + 10, 40, 5, "S");
      doc.text(`${profile?.fullNames} ${profile?.surname}`, 18, textline + 10);
      doc.rect(55, reactlines + 10, 40, 5, "S");
      doc.text(`${profile?.mainDOB}`, 65, textline + 10);
      doc.rect(95, reactlines + 10, 40, 5, "S");
      doc.text('Accidental', 106, textline + 10);
      doc.rect(135, reactlines + 10, 30, 5, "S");
      doc.text(`R${selfCoverData?.accidentalAmount}`, 145, textline + 10);
      doc.rect(165, reactlines + 10, 30, 5, "S");
      doc.text(`R${selfCoverData?.accidentalCost.toFixed(2)}`, 173, textline + 10);
      pageline = textline + 10;
    }

    if (profile && selfCoverData?.incomeCost > 0) {
      doc.rect(15, reactlines + 15, 40, 5, "S");
      doc.text(`${profile?.fullNames} ${profile?.surname}`, 18, textline + 15);
      doc.rect(55, reactlines + 15, 40, 5, "S");
      doc.text(`${profile?.mainDOB}`, 65, textline + 15);
      doc.rect(95, reactlines + 15, 40, 5, "S");
      doc.text('Income', 106, textline + 15);
      doc.rect(135, reactlines + 15, 30, 5, "S");
      doc.text(`R${selfCoverData?.incomeAmount}`, 145, textline + 15);
      doc.rect(165, reactlines + 15, 30, 5, "S");
      doc.text(`R${selfCoverData?.incomeCost.toFixed(2)}`, 173, textline + 15);
      pageline = textline + 15;
    }

    //Spouse Member Table
    if (spouceCover.length > 0) {
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 5, 40, 5, "FD");
      doc.rect(55, pageline + 5, 40, 5, "FD");
      doc.rect(95, pageline + 5, 40, 5, "FD");
      doc.rect(135, pageline + 5, 30, 5, "FD");
      doc.rect(165, pageline + 5, 30, 5, "FD");

      doc.setFont("Helvetica", "bold");
      doc.setTextColor(255, 255, 255);
      doc.text("Spouse Member", 22, pageline + 9);
      doc.text("Date of Birth", 65, pageline + 9);
      doc.text("Cover Type", 105, pageline + 9);
      doc.text("Cover Amount", 140, pageline + 9);
      doc.text("Premium", 173, pageline + 9);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");

      rectLine = pageline + 5;
      textLine = pageline + 9;

      for (let s = 0; s < spouceCover.length; s++) {
        rectLine = rectLine + 5;
        textLine = textLine + 5;
        doc.rect(15, rectLine, 40, 5, "S");
        doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
        doc.rect(55, rectLine, 40, 5, "S");
        doc.text(spouceCover[s].dateOfBirth, 65, textLine);
        doc.rect(95, rectLine, 40, 5, "S");
        doc.text('Funeral', 106, textLine);
        doc.rect(135, rectLine, 30, 5, "S");
        doc.text(`R${spouceCover[s].benefit}`, 145, textLine);
        doc.rect(165, rectLine, 30, 5, "S");
        doc.text(`R${spouceCover[s].premium.toFixed(2)}`, 173, textLine);
        if (spouceCover[s].spouseAccidentalAmount > 0) {
          rectLine = rectLine + 5;
          textLine = textLine + 5;
          doc.rect(15, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
          doc.rect(55, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].dateOfBirth, 65, textLine);
          doc.rect(95, rectLine, 40, 5, "S");
          doc.text('Accidental', 106, textLine);
          doc.rect(135, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].spouseAccidentalAmount}`, 145, textLine);
          doc.rect(165, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].spouseAccidentalCost.toFixed(2)}`, 173, textLine);
        }
        if (spouceCover[s].spouseIncomeAmount > 0) {
          rectLine = rectLine + 5;
          textLine = textLine + 5;
          doc.rect(15, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
          doc.rect(55, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].dateOfBirth, 65, textLine);
          doc.rect(95, rectLine, 40, 5, "S");
          doc.text('Income', 106, textLine);
          doc.rect(135, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].spouseIncomeAmount}`, 145, textLine);
          doc.rect(165, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].spouseIncomeCost.toFixed(2)}`, 173, textLine);
        }
        pageline = textLine;
      }
    }

    //Covered Member Table
    if (memberCover.length > 0) {
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 5, 30, 5, "FD");
      doc.rect(45, pageline + 5, 30, 5, "FD");
      doc.rect(75, pageline + 5, 30, 5, "FD");
      doc.rect(105, pageline + 5, 25, 5, "FD");
      doc.rect(130, pageline + 5, 25, 5, "FD");
      doc.rect(155, pageline + 5, 20, 5, "FD");
      doc.rect(175, pageline + 5, 20, 5, "FD");

      doc.setFont("Helvetica", "bold");
      doc.setTextColor(255, 255, 255);
      doc.text("Covered Member", 17, pageline + 9);
      doc.text("Relationship", 51, pageline + 9);
      doc.text("Sub Relationship", 77, pageline + 9);
      doc.text("Date of Birth", 108, pageline + 9);
      doc.text("Cover Type", 134, pageline + 9);
      doc.text("Benefit", 159, pageline + 9);
      doc.text("Premium", 178, pageline + 9);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");

      let rectPosition = pageline + 5
      let textPosition = pageline + 9

      for (let s = 0; s < memberCover.length; s++) {
        rectPosition = rectPosition + 5;
        textPosition = textPosition + 5;
        doc.rect(15, rectPosition, 30, 5, "S");
        doc.text(memberCover[s].name + ' ' + memberCover[s].surname, 17, textPosition);
        doc.rect(45, rectPosition, 30, 5, "S");
        if (memberCover[s].memberRelation === 'ownChildren') doc.text("Own Children", 47, textPosition);
        if (memberCover[s].memberRelation === 'parents') doc.text("Parents", 47, textPosition);
        if (memberCover[s].memberRelation === 'formerSpouse') doc.text("Former Spouse", 47, textPosition);
        if (memberCover[s].memberRelation === 'widerFamily') doc.text("Wider Family", 47, textPosition);
        doc.rect(75, rectPosition, 30, 5, "S");
        doc.text(String(memberCover[s].relationship), 77, textPosition);
        doc.rect(105, rectPosition, 25, 5, "S");
        doc.text(memberCover[s].dateOfBirth, 109, textPosition);
        doc.rect(130, rectPosition, 25, 5, "S");
        doc.text("Funeral", 137, textPosition);
        doc.rect(155, rectPosition, 20, 5, "S");
        doc.text("R" + memberCover[s].benefit, 160, textPosition);
        doc.rect(175, rectPosition, 20, 5, "S");
        doc.text("R" + memberCover[s].premium.toFixed(2), 180, textPosition);
        pageline = textPosition;
      }
    }

    if (selfCoverData?.funeralCost > 0 || selfCoverData?.accidentalCost > 0 || selfCoverData?.incomeCost > 0 || spouceCover.length > 0 || memberCover.length > 0) {
      doc.setFont("Helvetica", "bold");
      doc.text("Premium Waiver:", 146, pageline + 5);
      doc.text("Premium Holiday:", 145, pageline + 10);
      doc.setFont("Helvetica", "normal");
      doc.rect(175, pageline + 1, 20, 5, "S");
      doc.rect(175, pageline + 6, 20, 5, "S");
      doc.rect(175, pageline + 11, 20, 5, "S");
      doc.rect(175, pageline + 16, 20, 5, "S");
      if (totalCost && totalCost.premiumWaiver) doc.text("R" + totalCost.premiumWaiverAmount, 180, pageline + 5); else doc.text("R0.00", 180, pageline + 5);
      if (totalCost && totalCost.premiumHoliday) doc.text("R" + totalCost.premiumHolidayAmount, 180, pageline + 10); else doc.text("R0.00", 180, pageline + 10);
      pageline = pageline + 10;
      doc.setFont("Helvetica", "bold");
      doc.text("Policy Fee:", 155, pageline + 5);
      doc.text("Total monthly premium:", 136, pageline + 10);
      doc.setFont("Helvetica", "normal");
      doc.text("R10.00", 180, pageline + 5);
      if (totalCost && totalCost.finalTotal) doc.text("R" + totalCost.finalTotal, 180, pageline + 10);
      else doc.text("R0.00", 180, pageline + 10);
      doc.setFont("Helvetica", "bold");
      doc.text("*An automatic annual benefit increase of 3% on the anniversary of the policy on all benefits selected on all lives insured", 15, pageline + 15);
      doc.text("is applicable on this policy.", 15, pageline + 20)
      doc.text("*An automatic annual premium increase of 5% on the anniversary of the policy on the monthly premium of all lives insur", 15, pageline + 25);
      doc.text("ed is applicable on this policy.", 15, pageline + 30);
      pageline = pageline + 38;
    }

    doc.setFont("Helvetica", "italic", "bold");
    doc.setTextColor("4068a1");
    doc.text("4.2 Deduction details:", 15, pageline);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "bold");
    doc.text("Predferred monthly deduction method:", 15, pageline + 6);
    doc.setFont("Helvetica", "normal");
    doc.setFillColor("4068a1");
    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'Payroll') doc.rect(78, pageline + 3, 4, 4, "FD");
    else doc.rect(78, pageline + 3, 4, 4);
    doc.text('Payroll deduction (complete section 4.2.1)', 83, pageline + 6);
    doc.setFillColor("4068a1");
    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'Payroll') doc.rect(145, pageline + 3, 4, 4);
    else doc.rect(145, pageline + 3, 4, 4, "FD");
    doc.text('Debit order(complete section 4.2.2)', 150, pageline + 6);

    pageline = pageline + 8;
    // PAYROLL METHOD
    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'Payroll') {
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("4.2.1 Payroll deduction Mandate & Authority:", 15, pageline + 5);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      doc.text("I, the undersigned:", 15, pageline + 10)
      pageline = pageline + 5;
      doc.setFont("Helvetica", "bold");
      doc.text("Name & Surname:", 15, pageline + 11);
      doc.rect(46, pageline + 7, 55, 5, "S");
      doc.text("ID Number.:", 15, pageline + 16);
      doc.rect(46, pageline + 12, 55, 5, "S");
      doc.text("Rank / Title:", 15, pageline + 21);
      doc.rect(46, pageline + 17, 55, 5, "S");
      doc.text("Start Date", 15, pageline + 26);
      doc.rect(46, pageline + 22, 55, 5, "S");
      doc.text("Force/Salary No.:", 105, pageline + 11);
      doc.rect(136, pageline + 7, 62, 5, "S");
      doc.text("Department/Unit:", 105, pageline + 16);
      doc.rect(136, pageline + 12, 62, 5, "S");
      doc.text("Payslip reference:", 105, pageline + 21);
      // doc.rect(136, pageline + 17, 62, 5, "S");
      doc.text("End date:", 105, pageline + 26);
      // doc.rect(136, pageline + 22, 62, 5, "S");

      doc.setFont("Helvetica", "normal");
      doc.text(profile.fullNames + ' ' + profile.surname, 48, pageline + 11);
      if (profile.IDNumber) doc.text(profile.IDNumber, 48, pageline + 16);
      if (profile.rankTitle) doc.text(profile.rankTitle, 48, pageline + 21);
      if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 48, pageline + 26);
      else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 48, pageline + 26);
      if (employeeDetails) {
        doc.text(employeeDetails.employeeNo, 138, pageline + 11);
        doc.text(employeeDetails.departmentUnit, 138, pageline + 16);
      }
      doc.setFont("Helvetica", "Bold");
      doc.text("SHIELD LIFE LTD (Deduction code 3294)", 136, pageline + 21);
      doc.setFont("Helvetica", "normal");
      doc.text("monthly until policy lapses/cancelled", 136, pageline + 26);

      pageline = pageline - 3;
      doc.text(`I authorise the Accountant of the Employer Group ${employeeDetails.employerName} to deduct the monthly payments from my salary as per`, 15, pageline + 35);
      doc.text("the above authority provided and remit it to Shield Life of which I am a member / Insured, each and every month and continuing ", 15, pageline + 40);
      doc.text("until cancelled by me in writing or until I substitute it with a new authorisation. I have read and accepted the terms and conditions", 15, pageline + 45);
      doc.text("as set out below.", 15, pageline + 50);
    }

    //DEBIT ORDER METHOD
    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'DebitOrder') {
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("4.2.2 Debit order Mandate & Authority:", 15, pageline + 5);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "bold");
      pageline = pageline + 3;
      doc.text("Account Holder Name", 15, pageline + 9);
      doc.text("Account Number:", 106, pageline + 9);
      doc.rect(50, pageline + 5, 55, 5, "S");
      doc.rect(136, pageline + 5, 62, 5, "S");
      doc.setFont("Helvetica", "normal");
      doc.text(profile.fullNames + ' ' + profile.surname, 52, pageline + 9);
      doc.text(bankingDetailsDO.AccountNumber, 138, pageline + 9);
      pageline = pageline - 2;
      doc.setFont("Helvetica", "bold");
      doc.text("Bank & Branch code:", 15, pageline + 16);
      doc.rect(50, pageline + 12, 55, 5, "S");
      doc.text("Type of account:", 15, pageline + 21);
      doc.rect(50, pageline + 17, 55, 5, "S");
      doc.text("Debit order date:", 15, pageline + 26);
      doc.rect(50, pageline + 22, 55, 5, "S");
      doc.text("First instalment date:", 15, pageline + 31);
      doc.text("Beneficiary:", 15, pageline + 36);
      doc.text("Other:", 106, pageline + 16);
      doc.rect(136, pageline + 12, 62, 5, "S");
      doc.text("Other:", 106, pageline + 21);
      doc.rect(136, pageline + 17, 62, 5, "S");
      doc.text("Bank statement ref:", 106, pageline + 26);
      doc.text("Repayment term:", 106, pageline + 31);
      doc.text("Payment streem:", 106, pageline + 42);
      doc.setFont("Helvetica", "normal");
      doc.text(bankingDetailsDO.Bank + '  ' + bankingDetailsDO.BranchCode, 52, pageline + 16);
      doc.text(bankingDetailsDO.AccountType, 52, pageline + 21);
      doc.text(bankingDetailsDO.debitOrderDate, 52, pageline + 26);
      if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 52, pageline + 31);
      else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 52, pageline + 31);
      doc.text("Shield Life Ltd", 52, pageline + 36);
      doc.text("SLL FUNERAL", 136, pageline + 26);
      doc.setFont("Helvetica", "normal");
      doc.text("above frequency until policy lapses/policy", 136, pageline + 31);
      doc.text("cancelled", 136, pageline + 36)
      doc.text("Debicheck", 136, pageline + 42);
    }

    if (pageline > 230) {
      doc.addPage();
      pageline = -50;
    }

    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'Payroll') {
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("4.2.2 Alterations to method of payment in case of unavailability of payroll deduction", 15, pageline + 60);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.text("I hereby authorise the method of payment to be altered to a Debit Order in the event of me not qualifying for payroll", 15, pageline + 65);
      doc.text("deduction.", 15, pageline + 70);
      doc.setFont("Helvetica", "bold");
      doc.setFillColor("4068a1");
      if (bankingDetailsDO.alternativeMethod === "yes") doc.rect(160, pageline + 67, 4, 4, "FD");
      else doc.rect(160, pageline + 67, 4, 4);
      doc.text('Yes', 165, pageline + 70);
      doc.setFillColor("4068a1");
      if (bankingDetailsDO.alternativeMethod === "no") doc.rect(175, pageline + 67, 4, 4, "FD");
      else doc.rect(175, pageline + 67, 4, 4);
      doc.text('No', 180, pageline + 70);

      if (bankingDetailsDO.alternativeMethod === "yes") {
        if (pageline !== -50) {
          doc.addPage();
          pageline = 5;
        } else pageline = 25;

        doc.setTextColor("4068a1");
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("4.2.3 Debit order Mandate & Authority:", 15, pageline + 5);
        doc.setTextColor("#444");
        doc.setFont("Helvetica", "bold");
        pageline = pageline + 3;
        doc.text("Account Holder Name", 15, pageline + 9);
        doc.text("Account Number:", 106, pageline + 9);
        doc.rect(50, pageline + 5, 55, 5, "S");
        doc.rect(136, pageline + 5, 62, 5, "S");
        doc.setFont("Helvetica", "normal");
        doc.text(profile.fullNames + ' ' + profile.surname, 52, pageline + 9);
        doc.text(bankingDetailsDO.AccountNumber, 138, pageline + 9);
        pageline = pageline - 2;
        doc.setFont("Helvetica", "bold");
        doc.text("Bank & Branch code:", 15, pageline + 16);
        doc.rect(50, pageline + 12, 55, 5, "S");
        doc.text("Type of account:", 15, pageline + 21);
        doc.rect(50, pageline + 17, 55, 5, "S");
        doc.text("Debit order date:", 15, pageline + 26);
        doc.rect(50, pageline + 22, 55, 5, "S");
        doc.text("First instalment date:", 15, pageline + 31);
        doc.text("Beneficiary:", 15, pageline + 36);
        doc.text("Other:", 106, pageline + 16);
        doc.rect(136, pageline + 12, 62, 5, "S");
        doc.text("Other:", 106, pageline + 21);
        doc.rect(136, pageline + 17, 62, 5, "S");
        doc.text("Bank statement ref:", 106, pageline + 26);
        doc.text("Repayment term:", 106, pageline + 31);
        doc.text("Payment streem:", 106, pageline + 42);
        doc.setFont("Helvetica", "normal");
        doc.text(bankingDetailsDO.Bank + '  ' + bankingDetailsDO.BranchCode, 52, pageline + 16);
        doc.text(bankingDetailsDO.AccountType, 52, pageline + 21);
        doc.text(bankingDetailsDO.debitOrderDate, 52, pageline + 26);
        if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 52, pageline + 31);
        else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 52, pageline + 31);
        doc.text("Shield Life Ltd", 52, pageline + 36);
        doc.text("SLL FUNERAL", 136, pageline + 26);
        doc.setFont("Helvetica", "normal");
        doc.text("above frequency until policy lapses/policy", 136, pageline + 31);
        doc.text("cancelled", 136, pageline + 36)
        doc.text("Debicheck", 136, pageline + 42);
        pageline = pageline + 3;
        doc.text("I authorise Shield Life to issue and deliver debit order payment instructions to your banker for collection against my Account and", 15, pageline + 45);
        doc.text("Bank used by my employer to deposit my salaries / wages, which account may differ from the account specified above on condit", 15, pageline + 50);
        doc.text("ion that the sum of each payment instruction and frequency of payment requests will never exceed the obligations as agreed and", 15, pageline + 55);
        doc.text("defined in the Agreement specified.", 15, pageline + 60);
        doc.text("My bank’s participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules applica", 15, pageline + 65);
        doc.text("ble to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil the obligations", 15, pageline + 70);
        doc.text("as defined in the agreement. My authorisation is subject thereto that the same payment instruction may never be presented simu", 15, pageline + 75);
        doc.text("ltaneously in more than one payment stream during the same repayment cycle or, if unpaid in a payment stream selected be rep", 15, pageline + 80);
        doc.text("resented in the same cycle in another payment stream. I can only service the obligations defined in here if the payment instruction", 15, pageline + 85);
        doc.text("are executed as close as possible to when I receive my salary or wages which dates vary from month to month, especially during", 15, pageline + 90);
        doc.text("December of each year. To curb against (1) unpaid bank charges (2) losing the benefits described in the agreement quoted (3)", 15, pageline + 95);
        doc.text("incurring penalties due to non payment; I explicitly authorise Shield Life to utilise the functionality of Tracking supported on the", 15, pageline + 100);
        doc.text("Debicheck Payment Streams especially after unsuccessful attempts on the Debicheck Payment Stream.", 15, pageline + 105);
        doc.text("Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above mentioned", 15, pageline + 110);
        doc.text("account will be interrogated for a defined period until this period has lapsed or until payment has been received. I hereby agree", 15, pageline + 115);
        doc.text("that subsequent payment instructions will continue to be delivered in terms of the authority until all obligations have been paid.", 15, pageline + 120);
        doc.text("This authorisation will remain in force until cancelled by me in writing. I hereby acknowledge that my bank will charge fees to my", 15, pageline + 125);
        doc.text("account as agreed with them once they process this instruction. I foresee that I may change my bank and bank account particular", 15, pageline + 130);
        doc.text("reflected in here, in which instance I will notify the beneficiaries specified, or any new beneficiary, should the agreement benefici", 15, pageline + 135);
        doc.text("ary or the ceded or assigned to any third party. Should I however forget to notify the beneficiary or the assigned third party and if", 15, pageline + 140);
        doc.text("the benef assigned third party obtain my new bank particulars, on own account, this mandate will not lapse. This issued mandate", 15, pageline + 145);
        doc.text("will cover the obtained bank information and the beneficiary and the assigned third party may attach such new information, to this", 15, pageline + 150);
        doc.text("signed document as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date chan", 15, pageline + 155);
        doc.text("ges agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal Entity.", 15, pageline + 160);
        doc.text("I understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South Afric", 15, pageline + 165);
        doc.text("an Banks I also understand that details of each withdrawal will be printed on my/our bank statement. Such must contain the agree", 15, pageline + 170);
        doc.text("ment reference number as stipulated above which number shall enable me to match the withdrawals with this agreement. I ackno", 15, pageline + 175);
        doc.text("wledge that this Authority may be ceded or assigned to a third party but only in the event that this agreement is also ceded or assi", 15, pageline + 180);
        doc.text("gned to that third party.", 15, pageline + 185);
        doc.setFont("Helvetica", "Bold");
        pageline = pageline - 5;
        doc.text("Terms & Conditions", 15, pageline + 195);
        doc.setFont("Helvetica", "normal");
        doc.text("I instruct and authorise Shield Life to draw against my nominated bank account (or any other bank or branch to which I may trans", 15, pageline + 200);
        doc.text("fer my account) the amount necessary for the payment of the monthly premium due in respect of my insurance, on the day nomin", 15, pageline + 205);
        doc.text("ated by me each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.", 15, pageline + 210);
        doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 215);
        doc.text("I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have", 15, pageline + 220);
        doc.text("been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 225);
        doc.text("I agree that in the event that the payment date falls on a Saturday, Sunday or recognised South African public holiday, the payme", 15, pageline + 230);
        doc.text("nt day will automatically be the first preceding business day.", 15, pageline + 235);
        doc.text("I agree that should there be insufficient funds in my account to meet the obligation, Shield Life is entitled  to track my account and", 15, pageline + 240);
        doc.text("re-present the instruction for payment as soon as sufficient funds are available in my account. Such will contain Shield Life Limited", 15, pageline + 245);
        doc.text("as reference which shall enable me to match the withdrawals with this agreement.", 15, pageline + 250);
        doc.text("I agree to pay any bank charges relating to this debit order instruction. ", 15, pageline + 255);
        doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request", 15, pageline + 260);
        doc.text("Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted from my", 15, pageline + 265);
        doc.text("bank account. I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 270);

        if (pageline > 20) {
          doc.addPage();
          pageline = -260;
        }
        doc.addImage(signature, "JPEG", 25, pageline + 265, 35, 35, "final", "MEDIUM");
        doc.text("____________________________", 20, pageline + 285);
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("Signature of Premium Payer", 25, pageline + 290);
        if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 100, pageline + 285);
        doc.text("Date:", 100, pageline + 278);
        doc.setFont("Helvetica", "normal");
        doc.text(timeStamp, 110, pageline + 278);
        doc.text("__________________________________________________", 110, pageline + 280);
        pageline = pageline + 300;
      } else {
        doc.addPage();
        if (pageline > 100) {
          pageline = -50;
        }
        doc.setFont("Helvetica", "Bold");
        doc.text("Terms & Conditions", 15, pageline + 60);
        doc.setFont("Helvetica", "normal");
        doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 65);
        doc.text("I understand that should I cancel the authority after the 31 - day period, I shall not be entitled to any refund of amounts which", 15, pageline + 70);
        doc.text("have been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 75);
        pageline = pageline + 10;
        doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease / increase in subscription or should I request ", 15, pageline + 70);
        doc.text("Shield Life to decrease / increase the premium for certain reason, I confirm that the adjusted premium may be deducted.", 15, pageline + 75);
        doc.text("I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 80);
        pageline = pageline - 15;

        doc.addImage(signature, "JPEG", 25, pageline + 85, 35, 35, "final", "MEDIUM");
        doc.text("____________________________", 20, pageline + 110);
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("Signature of Premium Payer", 25, pageline + 115);
        if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 100, pageline + 105);
        doc.text("Date:", 100, pageline + 110);
        doc.setFont("Helvetica", "normal");
        doc.text(timeStamp, 110, pageline + 110);
        doc.text("__________________________________________________", 110, pageline + 113);
        pageline = pageline + 125;
      }
    }

    //DEBIT ORDER METHOD
    if (bankingDetailsDO && bankingDetailsDO.paymentMethod === 'DebitOrder') {
      doc.text("I authorise Shield Life to issue and deliver debit order payment instructions to your banker for collection against my Account and", 15, pageline + 45);
      doc.text("Bank used by my employer to deposit my salaries / wages, which account may differ from the account specified above on condit", 15, pageline + 50);
      doc.text("ion that the sum of each payment instruction and frequency of payment requests will never exceed the obligations as agreed and", 15, pageline + 55);
      doc.text("defined in the Agreement specified.", 15, pageline + 60);
      doc.text("My bank’s participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules applica", 15, pageline + 65);
      doc.text("ble to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil the obligations", 15, pageline + 70);
      doc.text("as defined in the agreement. My authorisation is subject thereto that the same payment instruction may never be presented simu", 15, pageline + 75);
      doc.text("ltaneously in more than one payment stream during the same repayment cycle or, if unpaid in a payment stream selected be rep", 15, pageline + 80);
      doc.text("resented in the same cycle in another payment stream. I can only service the obligations defined in here if the payment instruction", 15, pageline + 85);
      doc.text("are executed as close as possible to when I receive my salary or wages which dates vary from month to month, especially during", 15, pageline + 90);
      doc.text("December of each year. To curb against (1) unpaid bank charges (2) losing the benefits described in the agreement quoted (3)", 15, pageline + 95);
      doc.text("incurring penalties due to non payment; I explicitly authorise Shield Life to utilise the functionality of Tracking supported on the", 15, pageline + 100);
      doc.text("Debicheck Payment Streams especially after unsuccessful attempts on the Debicheck Payment Stream.", 15, pageline + 105);
      doc.text("Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above mentioned", 15, pageline + 110);
      doc.text("account will be interrogated for a defined period until this period has lapsed or until payment has been received. I hereby agree", 15, pageline + 115);
      doc.text("that subsequent payment instructions will continue to be delivered in terms of the authority until all obligations have been paid.", 15, pageline + 120);
      doc.text("This authorisation will remain in force until cancelled by me in writing. I hereby acknowledge that my bank will charge fees to my", 15, pageline + 125);
      doc.text("account as agreed with them once they process this instruction. I foresee that I may change my bank and bank account particular", 15, pageline + 130);
      doc.text("reflected in here, in which instance I will notify the beneficiaries specified, or any new beneficiary, should the agreement benefici", 15, pageline + 135);
      doc.text("ary or the ceded or assigned to any third party. Should I however forget to notify the beneficiary or the assigned third party and if", 15, pageline + 140);
      doc.text("the benef assigned third party obtain my new bank particulars, on own account, this mandate will not lapse. This issued mandate", 15, pageline + 145);
      doc.text("will cover the obtained bank information and the beneficiary and the assigned third party may attach such new information, to this", 15, pageline + 150);
      doc.text("signed document as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date chan", 15, pageline + 155);
      doc.text("ges agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal Entity.", 15, pageline + 160);
      doc.text("I understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South Afric", 15, pageline + 165);
      doc.text("an Banks I also understand that details of each withdrawal will be printed on my/our bank statement. Such must contain the agree", 15, pageline + 170);
      doc.text("ment reference number as stipulated above which number shall enable me to match the withdrawals with this agreement. I ackno", 15, pageline + 175);
      if (pageline > 15) {
        doc.addPage();
        pageline = -170;
      }
      doc.text("wledge that this Authority may be ceded or assigned to a third party but only in the event that this agreement is also ceded or assi", 15, pageline + 180);
      doc.text("gned to that third party.", 15, pageline + 185);
      doc.setFont("Helvetica", "Bold");
      doc.text("Terms & Conditions", 15, pageline + 195);
      doc.setFont("Helvetica", "normal");
      doc.text("I instruct and authorise Shield Life to draw against my nominated bank account (or any other bank or branch to which I may trans", 15, pageline + 200);
      doc.text("fer my account) the amount necessary for the payment of the monthly premium due in respect of my insurance, on the day nomin", 15, pageline + 205);
      doc.text("ated by me each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.", 15, pageline + 210);
      doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 215);
      doc.text("I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have", 15, pageline + 220);
      doc.text("been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 225);
      doc.text("I agree that in the event that the payment date falls on a Saturday, Sunday or recognised South African public holiday, the payme", 15, pageline + 230);
      doc.text("nt day will automatically be the first preceding business day.", 15, pageline + 235);
      doc.text("I agree that should there be insufficient funds in my account to meet the obligation, Shield Life is entitled  to track my account and", 15, pageline + 240);
      doc.text("re-present the instruction for payment as soon as sufficient funds are available in my account. Such will contain Shield Life Limited", 15, pageline + 245);
      doc.text("as reference which shall enable me to match the withdrawals with this agreement.", 15, pageline + 250);
      doc.text("I agree to pay any bank charges relating to this debit order instruction. ", 15, pageline + 255);
      doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request", 15, pageline + 260);
      doc.text("Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted from my", 15, pageline + 265);
      doc.text("bank account. I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 270);

      doc.addImage(signature, "JPEG", 25, pageline + 260, 35, 35, "final", "MEDIUM");
      doc.text("____________________________", 20, pageline + 285);
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("Signature of Premium Payer", 25, pageline + 290);
      if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 100, pageline + 280);
      doc.text("Date:", 100, pageline + 285);
      doc.setFont("Helvetica", "normal");
      doc.text(timeStamp, 110, pageline + 285);
      doc.text("________________________________________________", 110, pageline + 287);
      doc.addPage();
      pageline = 5;
    }

    if (pageline > 250) {
      doc.addPage();
      pageline = 5;
    }
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Representative confirmation and declaration:", 15, pageline + 5);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    pageline = pageline - 7;
    doc.text("Are you a Domestic Politically Exposed Person (DPEP)?", 15, pageline + 20);
    doc.setFillColor("4068a1");
    if (checkInfoData) {
      if (checkInfoData.dpep === 'Yes') doc.rect(135, pageline + 17, 4, 4, "FD");
      else doc.rect(135, pageline + 17, 4, 4);
      doc.text('Yes', 141, pageline + 20);
      doc.setFillColor("4068a1");
      if (checkInfoData.dpep === 'No') doc.rect(165, pageline + 17, 4, 4, "FD");
      else doc.rect(165, pageline + 17, 4, 4);
      doc.text('No', 171, pageline + 20);
      doc.text("If yes, please provide details:", 15, pageline + 25);
      doc.text(checkInfoData.dpepDetails, 80, pageline + 25);
      doc.text("___________________________________________", 80, pageline + 27);
      doc.text("Are you a Foreign Politically Exposed Person (FPEP)?", 15, pageline + 32);
      doc.setFillColor("4068a1");
      if (checkInfoData.fpep === 'Yes') doc.rect(135, pageline + 29, 4, 4, "FD");
      else doc.rect(135, pageline + 29, 4, 4);
      doc.text('Yes', 141, pageline + 32);
      doc.setFillColor("4068a1");
      if (checkInfoData.fpep === 'No') doc.rect(165, pageline + 29, 4, 4, "FD");
      else doc.rect(165, pageline + 29, 4, 4);

      doc.text('No', 171, pageline + 32);
      doc.text("If yes, please provide details:", 15, pageline + 37);
      doc.text(checkInfoData.fpepDetails, 80, pageline + 37);
      doc.text("___________________________________________", 80, pageline + 39);

      doc.text("Are you a Prominent Influential Person(PIP)?", 15, pageline + 45);
      doc.setFillColor("4068a1");
      if (checkInfoData.pip === 'Yes') doc.rect(135, pageline + 42, 4, 4, "FD");
      else doc.rect(135, pageline + 42, 4, 4);
      doc.text('Yes', 141, pageline + 45);
      doc.setFillColor("4068a1");
      if (checkInfoData.pip === 'No') doc.rect(165, pageline + 42, 4, 4, "FD");
      else doc.rect(165, pageline + 42, 4, 4);
      doc.text('No', 171, pageline + 45);
      doc.text("If yes, please provide details:", 15, pageline + 50);
      doc.text(checkInfoData.pipDetails, 80, pageline + 50);
      doc.text("___________________________________________", 80, pageline + 52);

      pageline = pageline + 12;
      doc.text("Is a close family member or any associates of yours a DPEP or FPEP or PIP?", 15, pageline + 45);
      doc.setFillColor("4068a1");
      if (checkInfoData.closeDpepFppo === 'Yes') doc.rect(135, pageline + 42, 4, 4, "FD");
      else doc.rect(135, pageline + 42, 4, 4);
      doc.text('Yes', 141, pageline + 45);
      doc.setFillColor("4068a1");
      if (checkInfoData.closeDpepFppo === 'No') doc.rect(165, pageline + 42, 4, 4, "FD");
      else doc.rect(165, pageline + 42, 4, 4);
      doc.text('No', 171, pageline + 45);
      doc.text("If yes, please provide details:", 15, pageline + 50);
      doc.text(checkInfoData.closeDpepFppoDetails, 80, pageline + 50);
      doc.text("___________________________________________", 80, pageline + 52);
    }
    doc.text("I, the undersigned hereby certify as follow:", 15, pageline + 60);
    doc.text("1. I have personally scanned in the normal course of business the documentation on the date of signature hereof by means", 15, pageline + 65);
    doc.text("of digital image capturing.", 19, pageline + 70);
    doc.text("2. All the documentations scanned were original documents and each scan resulted in a true, complete and unaltered image", 15, pageline + 75);
    doc.text("of the original document.", 19, pageline + 80);

    doc.setFont("Helvetica", "bold");
    if (representativeData && representativeData.representativeName) {
      doc.text("Representative First Name:", 15, pageline + 90);
      doc.text("Representative Last Name:", 15, pageline + 100);
      if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 15, pageline + 110);
      doc.text("Branch:", 100, pageline + 90);
      doc.text("Date:", 100, pageline + 100);
      doc.setFont("Helvetica", "normal");
      doc.text(representativeData.representativeName, 62, pageline + 90);
      doc.text("_____________________", 60, pageline + 92);
      doc.text(representativeData.representativeSurname, 62, pageline + 100);
      doc.text("_______________________", 60, pageline + 102);
      doc.text("_______________________", 112, pageline + 92);
      doc.text(timeStamp, 110, pageline + 100);
      doc.text("______________________________________________", 112, pageline + 102);
    } else {
      doc.text("Full name & surname:", 15, pageline + 90);
      doc.text("Signature of representative:", 15, pageline + 100);
      if (representativeData && representativeData.representativeCode) doc.text("Representative Code: " + representativeData.representativeCode, 15, pageline + 110);
      doc.text("Branch:", 100, pageline + 90);
      doc.text("Date:", 100, pageline + 100);
      doc.setFont("Helvetica", "normal");
      if (profile) doc.text(profile.fullNames + ' ' + profile.surname, 62, pageline + 90);
      doc.text("_____________________", 60, pageline + 92);
      if (signature) doc.addImage(signature, "JPEG", 62, pageline + 80, 35, 35, "final", "MEDIUM");
      doc.text("_______________________", 60, pageline + 105);
      doc.text("_______________________", 112, pageline + 92);
      doc.text(timeStamp, 110, pageline + 100);
      doc.text("______________________________________________", 112, pageline + 102);
    }


    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Reason for Choosing Plan:", 15, pageline + 120);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    let line = pageline + 127;
    let i = 0;

    if (addInfo && addInfo.affordableFunerral) {
      i = i + 1;
      doc.text(i + ". Affordable Funeral Cover", 15, line);
      line = line + 5
    }

    if (addInfo && addInfo.funeralMyself) {
      i = i + 1;
      doc.text(i + '. Funeral Cover for Myself', 15, line);
      line = line + 5;
    }

    if (addInfo && addInfo.funeralMyFamily) {
      i = i + 1;
      doc.text(i + '. Funeral Cover for my Family', 15, line);
      line = line + 5;
    }

    if (addInfo && addInfo.funeralExtendedFamily) {
      i = i + 1;
      doc.text(i + '. Funeral Cover for my Extended Family', 15, line);
      line = line + 5;
    }

    if (addInfo && addInfo.additionalBenifits) {
      i = i + 1;
      doc.text(i + '. Additional Benifits', 15, line);
      line = line + 5;
    }

    if (addInfo && addInfo.other) {
      i = i + 1;
      doc.text(i + '. I am confident that the fair treatment of policy holders is central to the insurer’s culture', 15, line);
    }

    doc.addPage();
    pageline = 10;
    doc.setTextColor("red");
    doc.setFont("Helvetica", "bold");
    doc.text("The following does not form part of the application form but are information that needs to be communicated to clients.", 15, pageline + 5);
    doc.setFontSize(16);
    doc.setTextColor("#444");
    doc.text("Statutory Notice", 90, pageline + 15);
    doc.setFontSize(9);
    doc.setFont("Helvetica", "normal");
    doc.text("The notice does not form part of the Insurance Contract nor any other document, but you have the right to the", 25, pageline + 20);
    doc.text("following information.", 90, pageline + 25);
    doc.setTextColor("4068a1");
    doc.setFont("Helvetica", "italic", "bold");
    doc.setFontSize(11);
    doc.text("Details about other parties involved:", 15, pageline + 35);
    doc.setFont("Helvetica", "bold");
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("Broker details:", 90, pageline + 40);
      doc.text("Insurer details:", 155, pageline + 40);
    } else {
      doc.text("The Insurer and Broker", 110, pageline + 40);
    }
    doc.setFontSize(9);
    doc.setTextColor("#444");

    //first
    doc.text("Name:", 15, pageline + 45);
    doc.text("Authorised FSP no:", 15, pageline + 50);
    doc.text("Registration no:", 15, pageline + 55);
    doc.text("Tel no:", 15, pageline + 60);
    doc.text("Email:", 15, pageline + 65);
    doc.text("Website:", 15, pageline + 70);
    doc.text("Physical Address:", 15, pageline + 75);
    doc.text("Postal Address:", 15, pageline + 95);

    doc.setFont("Helvetica", "normal");
    //middle
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("Maxi Forces Financial Services (Pty) Ltd", 70, pageline + 45);
      doc.text("1966", 70, pageline + 50);
      doc.text("1995/004244/07", 70, pageline + 55);
      if (code && code.match('scs')) doc.text('0861 113 112', 70, pageline + 60); else doc.text('0861 222 303', 70, pageline + 60);
      doc.text("info@maxiforces.co.za", 70, pageline + 65);
      doc.text("1293 South Street,", 70, pageline + 75);
      doc.text("Maxi Building,", 70, pageline + 80);
      doc.text("Centurion,", 70, pageline + 85);
      doc.text("0157", 70, pageline + 90);
    }

    //last
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("Shield Life® Limited", 140, pageline + 45);
      doc.text("47477", 140, pageline + 50);
      doc.text("2016/249174/06", 140, pageline + 55);
      doc.text("0861 777 353", 140, pageline + 60);
      doc.text("css@shieldlife.co.za", 140, pageline + 65);
      doc.text("www.shieldlife.co.za", 140, pageline + 70);
      doc.text("1293 South Street,", 140, pageline + 75);
      doc.text("Maxi Building,", 140, pageline + 80);
      doc.text("Centurion,", 140, pageline + 85);
      doc.text("0157", 140, pageline + 90);
      doc.text("PO Box 15473,", 140, pageline + 95);
      doc.text("Lyttleton,", 140, pageline + 100);
      doc.text("0140", 140, pageline + 105);
    } else {
      doc.text("Shield Life® Limited", 90, pageline + 45);
      doc.text("47477", 90, pageline + 50);
      doc.text("2016/249174/06", 90, pageline + 55);
      doc.text("0861 777 353", 90, pageline + 60);
      doc.text("css@shieldlife.co.za", 90, pageline + 65);
      doc.text("www.shieldlife.co.za", 90, pageline + 70);
      doc.text("1293 South Street,", 90, pageline + 75);
      doc.text("Maxi Building,", 90, pageline + 80);
      doc.text("Centurion,", 90, pageline + 85);
      doc.text("0157", 90, pageline + 90);
      doc.text("PO Box 15473,", 90, pageline + 95);
      doc.text("Lyttleton,", 90, pageline + 100);
      doc.text("0140", 90, pageline + 105);
    }

    pageline = pageline + 15;
    //first
    doc.setFont("Helvetica", "bold");
    doc.text("Authorised Category I", 15, pageline + 95);
    doc.text("products:", 15, pageline + 100);
    doc.text("Compliance officer:", 15, pageline + 115);
    doc.text("Complaints:", 15, pageline + 145);
    if (!code || (!code.match('sll') && !code.match('tsll'))) doc.text("Legal & contractual relationship:", 15, pageline + 195);
    else doc.text("FAIS Compliance officer:", 15, pageline + 165);
    doc.text("Professional Indemnity Insurance:", 15, pageline + 200);
    doc.text("Conflict of interest:", 15, pageline + 210);
    doc.text("Life insurer aprroved class and", 15, pageline + 225);
    doc.text("sub-classes of business:", 15, pageline + 230);
    doc.text("Claims procedure:", 15, pageline + 245);

    doc.setFont("Helvetica", "normal");
    //middle
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("Long-term Insurance: A, B1, B1-A, B2, B2-A", 70, pageline + 95);
      doc.text("Short-term Insurance: Personal Lines (PL), PL", 70, pageline + 100);
      doc.text("A1", 70, pageline + 105);
      doc.text("Moonstone Compliance (Pty) Ltd represented by", 70, pageline + 115);
      doc.text("Ms Géta Hancke", 70, pageline + 120);
      doc.text("Tel: (021) 883 8000", 70, pageline + 125);
      doc.text("Fax: (021) 880 0688", 70, pageline + 130);
      doc.text("E-mail: ghancke@moonstone.co.za", 70, pageline + 135);
      doc.text("PO Box 12662, Die Board, Stellenbosch,7613", 70, pageline + 140);
      doc.text("Must be in writing and will be attended to", 70, pageline + 145);
      doc.text("within 10 business days. We may request", 70, pageline + 150);
      doc.text("additional information, if needed and will", 70, pageline + 155);
      doc.text("keep the complainant up to date of the progress", 70, pageline + 160);
      doc.text("made. If the complaint is not resolved to the", 70, pageline + 165);
      doc.text("satisfaction of the complainant, they may approach", 70, pageline + 170);
      doc.text("the FAIS Ombud after expiry of the 90 day", 70, pageline + 175);
      doc.text("period. For further details, our Complaints", 70, pageline + 180);
      doc.text("Management Policy is available upon request:", 70, pageline + 185);
      doc.text("complaints@maxiforces.co.za", 70, pageline + 190);
      doc.text("Intermediary Service Agreement", 70, pageline + 195);
      doc.text("Professional Indemnity Cover is in place.", 70, pageline + 200);
      doc.text("The Conflict of Interest Management Policy", 70, pageline + 210);
      doc.text("can be obtained upon written request: ", 70, pageline + 215);
      doc.text("compliance@maxiforces.co.za", 70, pageline + 220);
    }
    //last
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.text("Long-term Insurance: A, B1, B1-A", 140, pageline + 95);
      doc.text("Short-term Insurance: Personal Lines", 140, pageline + 100);
      doc.text("(PL), PL A1 Participatory interest in a", 140, pageline + 105);
      doc.text("collective investment scheme", 140, pageline + 110);
      doc.text("The Compliance Department can be", 140, pageline + 115);
      doc.text("access through our website", 140, pageline + 120);
      doc.text("www.shieldlife.co.za or e-mail", 140, pageline + 125);
      doc.text("compliance@shieldlife.co.za", 140, pageline + 130);
      doc.text("The Compliance Policy can be access", 140, pageline + 145);
      doc.text("through our website", 140, pageline + 150);
      doc.text("www.shieldlife.co.za or e-mail ", 140, pageline + 155);
      doc.text("complaints@shieldlife.co.za", 140, pageline + 160);
      doc.text("Professional Indemnity Cover & Fidelity", 140, pageline + 200);
      doc.text("Cover are in place.", 140, pageline + 205);
      doc.text("The Conflict of Interest Policy can be", 140, pageline + 210);
      doc.text("obtained upon written request from the", 140, pageline + 215);
      doc.text("compliance officer.", 140, pageline + 220);
      doc.text("Risk: Individual and Group Death,", 140, pageline + 225);
      doc.text("Individual and Group Disability-Lump", 140, pageline + 230);
      doc.text("sum Funeral: Individual, Group", 140, pageline + 235);
      doc.text("Credit Life", 140, pageline + 240);
      doc.text("All claims enquiries should be address", 140, pageline + 245);
      doc.text("to: The Claims Department: Shield Life", 140, pageline + 250);
      doc.text("PO Box 7309, Centurion, 0046", 140, pageline + 255);
      doc.text("Tel: 0861 277 873", 140, pageline + 260);
      doc.text("Email: claims@shieldlife.co.za", 140, pageline + 265);
    } else {
      doc.text("Long-term Insurance: A, B1, B1-A", 90, pageline + 95);
      doc.text("Short-term Insurance: Personal Lines", 90, pageline + 100);
      doc.text("(PL), PL A1 Participatory interest in a", 90, pageline + 105);
      doc.text("collective investment scheme", 90, pageline + 110);
      doc.text("The Compliance Department can be", 90, pageline + 115);
      doc.text("access through our website", 90, pageline + 120);
      doc.text("www.shieldlife.co.za or e-mail", 90, pageline + 125);
      doc.text("compliance@shieldlife.co.za", 90, pageline + 130);
      doc.text("The Compliance Policy can be access", 90, pageline + 145);
      doc.text("through our website", 90, pageline + 150);
      doc.text("www.shieldlife.co.za or e-mail ", 90, pageline + 155);
      doc.text("complaints@shieldlife.co.za", 90, pageline + 160);
      doc.text("Syncerus Business Solutions (Pty) Ltd", 90, pageline + 165);
      doc.text("represented by: Ms Riana Steyn", 90, pageline + 170);
      doc.text("Tel: (082) 307 2287", 90, pageline + 175);
      doc.text("E-mail: riana@syncerus.co.za", 90, pageline + 180);
      doc.text("PO Box 2583, Brooklyn Square, 0075", 90, pageline + 185);
      doc.text("Professional Indemnity Cover & Fidelity", 90, pageline + 200);
      doc.text("Cover are in place.", 90, pageline + 205);
      doc.text("The Conflict of Interest Policy can be", 90, pageline + 210);
      doc.text("obtained upon written request from the", 90, pageline + 215);
      doc.text("compliance officer.", 90, pageline + 220);
      doc.text("Risk: Individual and Group Death,", 90, pageline + 225);
      doc.text("Individual and Group Disability-Lump", 90, pageline + 230);
      doc.text("sum Funeral: Individual, Group", 90, pageline + 235);
      doc.text("Credit Life", 90, pageline + 240);
      doc.text("All claims enquiries should be address", 90, pageline + 245);
      doc.text("to: The Claims Department: Shield Life", 90, pageline + 250);
      doc.text("PO Box 7309, Centurion, 0046", 90, pageline + 255);
      doc.text("Tel: 0861 277 873", 90, pageline + 260);
      doc.text("Email: claims@shieldlife.co.za", 90, pageline + 265);
    }

    doc.addPage();
    pageline = 10;
    doc.setTextColor("4068a1");
    doc.setFont("Helvetica", "italic", "bold");
    doc.setFontSize(11);
    doc.text("Other important contact details:", 15, pageline + 15);
    doc.setFontSize(9);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "bold");
    doc.text("The FAIS Ombud", 15, pageline + 20);
    doc.text("The Long-term Insurance Ombud", 110, pageline + 20);
    doc.setFont("Helvetica", "normal");
    doc.text("(For advice/policy related matters):", 15, pageline + 25);
    doc.text("The FAIS Ombud", 20, pageline + 30);
    doc.text("PO Box 74571, Lynnwood Ridge, 0040", 20, pageline + 35);
    doc.text("Tel: 086 066 3247 or Tel: (012) 762 5000", 20, pageline + 40);
    doc.text("Fax: (012) 348 3447", 20, pageline + 45);
    doc.text("Email: info@faisombud.co.za", 20, pageline + 50);
    doc.text("www.faisombud.co.za", 20, pageline + 55);
    doc.text("(For Claims/service related matters):", 110, pageline + 25);
    doc.text("Ombudsman for Long-term Insurance", 115, pageline + 30);
    doc.text("Claremont Central Building, 6th Floor, 6", 115, pageline + 35);
    doc.text("Vineyard Road, Claremont, 7700", 115, pageline + 40);
    doc.text("Tel: 0860 103 236 or (021) 657 5000", 115, pageline + 45);
    doc.text("Fax: (021) 674 0951", 115, pageline + 50);
    doc.text("Email: info@ombud.co.za", 115, pageline + 55);
    doc.text("www.ombud.co.za", 115, pageline + 60);

    pageline = 60;
    doc.setFont("Helvetica", "bold");
    doc.text("The Authority ", 15, pageline + 20);
    doc.text("The Information Regulator (South Africa)", 110, pageline + 20);
    doc.setFont("Helvetica", "normal");
    doc.text("(For market conduct matters):", 15, pageline + 25);
    doc.text("For Complaints on the Insurer that are not resolved to", 20, pageline + 30);
    doc.text("your satisfaction by the product supplier, please", 20, pageline + 35);
    doc.text("contact: The Financial Sector Conduct Authority", 20, pageline + 40);
    doc.text("PO Box 35655, Menlo Park, 0102", 20, pageline + 45);
    doc.text("Tel: (012) 428 8000, Fax: (012) 347 0221", 20, pageline + 50);
    doc.text("Email: info@fsca.co.za", 20, pageline + 55);
    doc.text("(For POPI matters):", 110, pageline + 25);
    doc.text("PO Box 31533, Braamfontein, Johannesburg, 2017", 115, pageline + 30);
    doc.text("Email: inforeg@justice.gov.za", 115, pageline + 35);
    doc.text("www.justice.gov.za/inforeg/docs1-gn.html#gn-paia", 115, pageline + 40);

    doc.setTextColor("4068a1");
    doc.setFont("Helvetica", "italic", "bold");
    doc.setFontSize(11);
    doc.text("Important information:", 15, pageline + 70);
    doc.setTextColor("#444");
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(9);
    doc.text("Premium obligations:", 15, pageline + 75);
    doc.text("Premium payments:", 15, pageline + 90);
    doc.text("Commencement of Insurance:", 15, pageline + 105);
    doc.text("Cooling of Rights:", 15, pageline + 125);
    doc.text("Expiry of Insurance cover:", 15, pageline + 150);
    doc.text("Cancellation by Insurer:", 15, pageline + 165);
    doc.text("Premium & Benefit increase:", 15, pageline + 190);
    doc.text("Remuneration:", 15, pageline + 200);
    doc.text("Information provided:", 15, pageline + 210);
    doc.text("Insurance Contract understanding:", 15, pageline + 225);

    doc.setFont("Helvetica", "normal");
    doc.text("The obligations that you assume as premium payer is indicated in the Insurance Contract.", 70, pageline + 75);
    doc.text("Ensure that you are fully aware of these obligations.", 70, pageline + 80);
    doc.text("The conditions of the Insurance Contract set out the due date of premiums and the", 70, pageline + 90);
    doc.text("consequences of non-payment of such premiums.", 70, pageline + 95);
    doc.text("Cover will be active from the first day of the month in which we receive the first premium.", 70, pageline + 105);
    doc.text("Premiums are payable monthly. A period of thirty (30) days grace is allowed for payment", 70, pageline + 110);
    doc.text("of each premium due and payable.", 70, pageline + 115);
    doc.text("Cooling off rights may be exercised within 31 days after inception of the membership,", 70, pageline + 125);
    doc.text("where no benefit has been paid or claimed. A written cancellation form must be completed", 70, pageline + 130);
    doc.text("and send to Shield Life® Ltd. Cooling off rights can not be exercised if other legislation", 70, pageline + 135);
    doc.text("prohibit it.", 70, pageline + 140);
    doc.text("All benefits and premiums will cease to be payable upon the events as described in the", 70, pageline + 150);
    doc.text("policy wording.", 70, pageline + 155);
    doc.text("If payment of premium is unsuccessful, the policy benefits are at risk and it is the", 70, pageline + 165);
    doc.text("responsibility of the Insured to notify the Insurer as to how to collect arrear premiums.", 70, pageline + 170);
    doc.text("If the Insured don’t, the Insurer may cancel the policy and the Insured will lose the", 70, pageline + 175);
    doc.text("cover and all premiums.", 70, pageline + 180);
    doc.text("Policy & Benefit increase are subject to decisions made by Shield Life® Limited.", 70, pageline + 190);
    doc.text("Commission will not exceed the statutory commission as defined by the Life Insurance Act.", 70, pageline + 200);
    doc.text("Decisions made by Shield Life® Ltd as the insurer, in respect of insurance or claim, may", 70, pageline + 210);
    doc.text("be influenced by the failure to provide correct or full information by the insured.", 70, pageline + 215);
    doc.text("Ensure you understand what benefits and exclusions are applicable to your Insurance", 70, pageline + 225);
    doc.text("Contract. Make sure you are acquainted with:", 70, pageline + 230);
    doc.text("what is not covered,", 80, pageline + 235);

    doc.addPage();
    pageline = 5;
    doc.text("what is restricted and", 80, pageline + 5);
    doc.text("If there are any special clauses.", 80, pageline + 10);
    doc.setFont("Helvetica", "bold");
    doc.text("Important information:", 15, pageline + 20);
    doc.setFont("Helvetica", "normal");
    doc.text("You will be informed of any material changes to the above mentioned information.", 70, pageline + 20);
    doc.text("This disclosure notice serves to provide you with the information in writing if any", 70, pageline + 25);
    doc.text("information was given to you orally.", 70, pageline + 30);
    doc.text("The reason for your claim to be repudiated will always be communicated to you in writing.", 70, pageline + 35);
    doc.text("In case of the insurer cancelling your policy, this will be done in writing to your last known", 70, pageline + 40);
    doc.text("address You will always be entitled to a copy of your policy, free of charge.", 70, pageline + 45);
    pageline = pageline - 10;
    doc.text("For instructions to access, verify, or object to personal information processed by Shield", 70, pageline + 65);
    doc.text("Life, please view our privacy notice on www.shieldlife.co.za", 70, pageline + 70);
    doc.text("Should any of my dependants or I have any concerns about the processing of our personal", 70, pageline + 75);
    doc.text("information, we may raise the matter with Shield Life's Information Officer at", 70, pageline + 80);
    doc.text("privacy@shieldlife.co.za", 70, pageline + 85);
    if (!code || (!code.match('sll') && !code.match('tsll'))) {
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.setFontSize(11);
      doc.text("Treating Customers Fairly (TCF) Principles", 15, pageline + 95);
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(9);
      doc.setTextColor("#444");
      doc.text("Shield Life Ltd and Maxi Forces Financial Services subscribe to the following 6 TCF principles during product creation to", 15, pageline + 100);
      doc.text("meet your requirements and expectations:", 15, pageline + 105);
      pageline = pageline + 5;
      doc.text("Culture", 25, pageline + 105);
      doc.text("Right Product", 25, pageline + 110);
      doc.text("Informed", 25, pageline + 115);
      doc.text("Suitable Advice", 25, pageline + 120);
      doc.text("Meet Expectations", 25, pageline + 125);
      doc.text("Excellent Service", 25, pageline + 130);
      doc.setFont("Helvetica", "normal");
      doc.text("Consumers are confident that fair treatment is central to our Company's culture", 60, pageline + 105);
      doc.text("Consumers have Products & Services that meet their needs", 60, pageline + 110);
      doc.text("Consumers received communication that is clear and in understandable language", 60, pageline + 115);
      doc.text("Consumers receive suitable advice aimed at their circumstances", 60, pageline + 120);
      doc.text("Consumers receive Products & Services that will meet their expectations", 60, pageline + 125);
      doc.text("Consumers experience no post-sale barriers", 60, pageline + 130);
      textline = pageline + 135;
      pageline = textline;
    } else pageline = pageline + 90;

    // pageline = pageline + 10;
    // doc.setFont("Helvetica", "bold");
    // doc.setFontSize(14);
    // doc.setTextColor("#444");
    // doc.text("Advice Record:  Funeral Cover Only", 65, pageline);
    // doc.setFontSize(9);
    // doc.setTextColor("red");
    // doc.setFont("Helvetica", "bold");
    // doc.text("The following does not form part of the application form but are information that needs to be communicated to new", 15, pageline + 5);
    // doc.text("clients.", 105, pageline + 10);
    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("1. General Details:", 15, pageline + 15);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "bold");
    // doc.setFontSize(9);
    // doc.rect(50, pageline + 17, 55, 5, "S");
    // doc.text("Full name & Surname:", 15, pageline + 20);
    // doc.rect(50, pageline + 22, 55, 5, "S");
    // doc.text("Cell:", 15, pageline + 25);
    // doc.rect(135, pageline + 17, 55, 5, "S");
    // doc.text("ID Number:", 110, pageline + 20);
    // doc.rect(135, pageline + 22, 55, 5, "S");
    // doc.text("Email:", 110, pageline + 25);
    // doc.setFont("Helvetica", "normal");
    // if (profile) {
    //   doc.text(profile.fullNames + ' ' + profile.surname, 52, pageline + 21);
    //   doc.text(profile.cellNumber, 52, pageline + 26);
    //   doc.text(profile.IDNumber, 137, pageline + 21);
    //   doc.text(profile.emailAddress, 137, pageline + 26);
    // }
    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("2. Needs analysis:", 15, pageline + 30);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("It is important to make sure that you have the right and enough funeral insurance to meet your and your family's needs, therefore", 15, pageline + 35);
    // doc.text("we need to analyse your funeral insurance needs by completing the below:", 15, pageline + 40);
    // doc.text("a)  Do you need funeral cover?", 15, pageline + 45);
    // doc.rect(160, pageline + 42, 4, 4);
    // doc.text("Yes", 165, pageline + 45);
    // doc.rect(175, pageline + 42, 4, 4);
    // doc.text("No", 180, pageline + 45);
    // doc.text("b)  Do you have existing or pre-existing funeral cover?", 15, pageline + 50);
    // doc.rect(160, pageline + 47, 4, 4);
    // doc.text("Yes", 165, pageline + 50);
    // doc.rect(175, pageline + 47, 4, 4);
    // doc.text("No", 180, pageline + 50);
    // doc.text("i) If yes to b), how many funeral policies do you have?", 20, pageline + 55);
    // doc.rect(160, pageline + 52, 40, 5, "S");
    // doc.text("ii) If yes to b), how much is your total existing funeral cover?", 20, pageline + 60);
    // doc.rect(160, pageline + 57, 40, 5, "S");
    // doc.text("iii) If yes to b), how much is your current total monthly premium?", 20, pageline + 65);
    // doc.rect(160, pageline + 62, 40, 5, "S");
    // doc.text("iv) If yes to b), have you recently, more than 31 days before signature date, cancelled a funeral ", 20, pageline + 70);
    // doc.text("policy with another Insurer?", 25, pageline + 75)
    // doc.rect(160, pageline + 72, 40, 5, "S");
    // doc.text("v) If yes to, did you complete a waiting period under that previous funeral policy?", 20, pageline + 80);
    // doc.rect(160, pageline + 77, 40, 5, "S");
    // doc.text("Waiver of waiting period only applicable on claim stage, if proper evidence of pre-existing funeral cover where the", 30, pageline + 85);
    // doc.text("waiting periods have been completed, can be provided.", 30, pageline + 90);
    // doc.text("c) What Is your monthly income?", 15, pageline + 95);
    // doc.rect(70, pageline + 92, 4, 4);
    // doc.text("R0 to R3000", 75, pageline + 95);
    // doc.rect(100, pageline + 92, 4, 4);
    // doc.text("R3001 to R6000", 105, pageline + 95);
    // doc.rect(135, pageline + 92, 4, 4);
    // doc.text("More than R6000", 140, pageline + 95);
    // doc.text("d) Do you want to add lives insured?", 15, pageline + 100);
    // doc.rect(160, pageline + 97, 4, 4);
    // doc.text("Yes", 165, pageline + 100);
    // doc.rect(175, pageline + 97, 4, 4);
    // doc.text("No", 180, pageline + 100);
    // doc.text("i) If yes to d), do you want to add your spouse/life partner?", 20, pageline + 105);
    // doc.rect(160, pageline + 102, 4, 4);
    // doc.text("Yes", 165, pageline + 105);
    // doc.rect(175, pageline + 102, 4, 4);
    // doc.text("No", 180, pageline + 105);
    // doc.text("ii) If yes to d), how many own children under the age of 18, do you want to add?", 20, pageline + 110);
    // doc.rect(160, pageline + 107, 40, 5, "S");
    // doc.text("iii) If yes to d), how many extended family members under the age of 75, do you want to add?", 20, pageline + 115);
    // doc.rect(160, pageline + 112, 40, 5, "S");
    // doc.text("e) What is the amount of funeral cover that the main life insured require?", 15, pageline + 120);
    // doc.rect(160, pageline + 117, 40, 5, "S");

    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("3. FSP detials:", 15, pageline + 125);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(10);
    // if (!code.match('sll') && !code.match('tsll')) {
    //   doc.text("Maxi Forces Financial Services(Pty) Ltd, reg.no.1995/004244/07, holds a category I Financial Service Provider License", 15, pageline + 130);
    //   doc.text("(FSP 1966).", 15, pageline + 135);
    //   doc.setFontSize(9);
    //   doc.text("We have a contract with Liberty, Assupol Life and Shield Life® Limited to provide financial services and intermediary services.", 20, pageline + 140);
    //   doc.text("In the preceding 12 months the business did not receive more than 30 % of total remuneration, including commission from any", 20, pageline + 145);
    //   doc.text("of the product suppliers and does not hold more than 10 % shares in these or other product providers.", 20, pageline + 150);
    //   doc.addPage();
    //   pageline = -145;
    //   doc.text("We have professional indemnity cover is in place.", 20, pageline + 155);
    //   doc.text("We can be reached through; Tel: 0861 222 303, email info@maxiforces.co.za or business address 1293 South Street, Maxi", 20, pageline + 160);
    //   doc.text("Centurion, 0157.", 20, pageline + 165);
    //   doc.text("Building, Complaints must be in writing and will be attended to within 10 business days.We may request additional information,", 20, pageline + 170);
    //   doc.text("if needed and will keep the complainant up to date of the progress made.For further details, our Complaints Management", 20, pageline + 175);
    //   doc.text("Policy is available upon request: complaints@maxiforces.co.za.", 20, pageline + 180);
    // } else {
    //   doc.setFontSize(9);
    //   doc.text("Shield Life Limited, reg. no. 2016/249174/06, is an Insurer licensed to provide Life Insurance products and holds a ", 20, pageline + 130);
    //   doc.text("category I Financial Service Provider License (FSP 47477).", 20, pageline + 135)
    //   doc.addPage();
    //   pageline = -175
    // }

    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("4. Representative details and commission payable:", 15, pageline + 185);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // if (!code.match('sll') && !code.match('tsll')) doc.text("I hereby request the representative(under supervision) of Maxi Forces Financial Services, to assist with and implement the Single", 15, pageline + 190);
    // else doc.text("I hereby request the representative(under supervision) of Shield Life, to assist with and implement the Single", 15, pageline + 190);
    // doc.text("Need I specifically requested.The representative is authorised to provide both scripted advice and intermediary services in terms", 15, pageline + 195);
    // doc.text("of the Financial Advisory and Intermediary Services Act 37 of 2002, in respect of the following licence categories: Long Term Cat", 15, pageline + 200);
    // doc.text("A and B1-A. The Financial Services Provider accepts responsibility for all actions of the representative performed within the course", 15, pageline + 205);
    // doc.text("and scope of his/her duties.The representative is appointed in terms of a written agreement with the Financial Services Provider.", 15, pageline + 210);
    // if (!code.match('sll') && !code.match('tsll')) doc.text("I understand that the Representative receives a commission of up to 22.5 % & or incentives from Shield Life Ltd.", 15, pageline + 215);
    // else if (code.match('sll')) doc.text("I understand that the Representative receives incentives from Shield Life Ltd.", 15, pageline + 215);
    // else if (code.match('tsll')) doc.text("I understand that the Representative receives a commission of up to 100 % of annual premium & or incentives from Shield Life Ltd.", 15, pageline + 215);
    // doc.text("A comprehensive needs analysis will not be conducted due to the limited nature of this mandate. I understand that whatever", 15, pageline + 220);
    // doc.text("advice implemented here may impact my financial situation.I am aware that the accuracy and completeness of the information", 15, pageline + 225);
    // doc.text("provided remains my responsibility, and I am aware that material non - disclosure could result in claims repudiation and loss of", 15, pageline + 230);
    // doc.text("benefits.", 15, pageline + 235);
    // doc.text("I understand that compliance with the Financial Intelligence Centre Act is required and have provided all required information and", 15, pageline + 240);
    // doc.text("documentation.", 15, pageline + 245);
    // doc.text("The proposed solutions to my needs have been explained to me in a manner that I UNDERSTAND.The following is a summary of", 15, pageline + 250);
    // doc.text("recommendations made by the representative, and the reason for the recommendation.The following products HAVE BEEN", 15, pageline + 255);
    // doc.text("EXPLAINED TO ME IN A MANNER THAT I UNDERSTAND–especially definitions, exclusions and limitations. I understand the", 15, pageline + 260);
    // doc.text("risk and cover provided and I have accepted / declined the following:", 15, pageline + 265);

    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("5. Statutory Disclosure:", 15, pageline + 270);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("We have professional indemnity cover is in place.", 15, pageline + 275);
    // if (!code.match('sll') && !code.match('tsll')) {
    //   doc.text("We can be reached through; Tel: 0861 222 303, email info@maxiforces.co.za or business address 1293 South Street, Maxi", 15, pageline + 280);
    //   doc.text("Building, Centurion, 0157.", 15, pageline + 285);
    // } else {
    //   doc.text("We can be reach through; Tel: 0861 777 353, email css@shieldlife.co.za or business address 1293 South Street, Maxi Building,", 15, pageline + 280);
    //   doc.text("Centurion, 0157.", 15, pageline + 285);
    // }
    // doc.text("Complaints must be in writing and will be attended to within 10 business days. We may request additional information, if needed", 15, pageline + 290);
    // doc.text("and will keep the complainant up to date of the progress made.For further details, our Complaints Management Policy is available", 15, pageline + 295);
    // doc.text("upon request: complaints@maxiforces.co.za.A copy of our `Conflict Of Interest Policy’ is also available upon request.", 15, pageline + 300);

    // pageline = pageline + 305;
    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("6. Compliance officer details:", 15, pageline);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // if (!code.match('sll') && !code.match('tsll')) {
    //   doc.text("Our compliance officer is Moonstone Compliance(Pty) Ltd represented by Ms Géta Hancke, Tel: (021) 883 8000, Fax: (021)", 15, pageline + 5);
    //   doc.text("880 0688, email: ghancke@moonstonecompliance.co.za and PO Box 1221, Die Boord, Stellenbosch, 7673", 15, pageline + 10);
    // } else {
    //   doc.text("Our compliance officer is Syncerus Business Solutions (Pty) Ltd represented by Ms Riana Steyn, Tel: (021) 883 8000, Fax: (021)", 15, pageline + 5);
    //   doc.text("880 0688, email: riana@syncerus.co.za and PO Box 2583, Die Boord, Brooklyn Square, 0075", 15, pageline + 10);
    // }
    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("7. Advice record:", 15, pageline + 15);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("The following is a summary of the selected product(s).The selected product(s)  HAVE BEEN EXPLAINED TO ME IN A MANNER", 15, pageline + 20);
    // doc.text("THAT I UNDERSTAND – especially definitions, exclusions and limitations.I understand the risk and cover provided and I have", 15, pageline + 25);
    // doc.text("accepted following:", 15, pageline + 30);
    // doc.text("The needs and objectives identified are:", 15, pageline + 35);
    // doc.rect(72, pageline + 32, 4, 4);
    // doc.text("Cover for main insured", 77, pageline + 35);
    // doc.rect(110, pageline + 32, 4, 4);
    // doc.text("Cover for immediate family", 115, pageline + 35);
    // doc.rect(154, pageline + 32, 4, 4);
    // doc.text("Cover for extended family", 159, pageline + 35);
    // doc.text("Plan selected:", 15, pageline + 40);
    // doc.rect(72, pageline + 37, 125, 5, "S");
    // doc.text("Reason for selection:", 15, pageline + 45);
    // doc.rect(72, pageline + 42, 125, 5, "S");

    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("8. Replacement of an existing funeral policy, if applicable:", 15, pageline + 50);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("Do you wish to replace an existing funeral policy?", 15, pageline + 55);
    // doc.rect(160, pageline + 52, 4, 4);
    // doc.text("Yes", 165, pageline + 55);
    // doc.rect(175, pageline + 52, 4, 4);
    // doc.text("No", 180, pageline + 55);
    // doc.text("If yes, please complete:", 15, pageline + 65);
    // doc.rect(65, pageline + 61, 65, 5, "S");
    // doc.text("Old Product", 88, pageline + 65);
    // doc.rect(130, pageline + 61, 65, 5, "S");
    // doc.text("New Product", 155, pageline + 65);
    // doc.rect(65, pageline + 66, 65, 5, "S");
    // doc.rect(130, pageline + 66, 65, 5, "S");
    // doc.text("Waiting periods", 20, pageline + 70);
    // doc.rect(65, pageline + 71, 65, 5, "S");
    // doc.rect(130, pageline + 71, 65, 5, "S");
    // doc.text("Exclusions", 20, pageline + 75);
    // doc.rect(65, pageline + 76, 65, 5, "S");
    // doc.rect(130, pageline + 76, 65, 5, "S");
    // doc.text("Cost and fees", 20, pageline + 80);
    // doc.text("Reason for replacement:", 20, pageline + 85);
    // doc.rect(65, pageline + 82, 130, 5, "S");

    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("9. Declaration by the representative:", 15, pageline + 90);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("I declare that the Advice Record is an accurate and complete record of the factual information that I provided to the client, which is", 15, pageline + 95);
    // doc.text("based upon the information provided by the client.", 15, pageline + 100);

    // pageline = pageline + 20;
    // doc.setFont("Helvetica", "bold");
    // doc.text("Full name & surname:", 15, pageline + 90);
    // doc.text("Signature of representative:", 15, pageline + 100);
    // doc.text("Branch:", 100, pageline + 90);
    // doc.text("Date:", 100, pageline + 100);
    // doc.setFont("Helvetica", "normal");
    // if (profile) doc.text(profile.fullNames + ' ' + profile.surname, 62, pageline + 90);
    // doc.text("_____________________", 60, pageline + 92);
    // if (signature) doc.addImage(signature, "JPEG", 62, pageline + 80, 35, 35, "final", "MEDIUM");
    // doc.text("_______________________", 60, pageline + 105);
    // doc.text("_______________________", 112, pageline + 92);
    // doc.text(timeStamp, 110, pageline + 100);
    // doc.text("______________________________________________", 110, pageline + 105);

    // doc.addPage();
    // pageline = 5;
    // doc.setTextColor("4068a1");
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.setFontSize(10);
    // doc.text("10. Declaration by client:", 15, pageline);
    // doc.setTextColor("#444");
    // doc.setFont("Helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text("I elect to:", 15, pageline + 5);
    // doc.rect(15, pageline + 7, 4, 4);
    // doc.text("Select the plan as stated in point 7.", 20, pageline + 10);
    // doc.rect(15, pageline + 12, 4, 4);
    // doc.text("To not select the plan as stated in point 7 and have been informed of the consequences", 20, pageline + 15);
    // doc.text("The representative gave me the relevant product disclosures in which comprehensive disclosures are made including the benefits,", 15, pageline + 25);
    // doc.text("contributions payable, commission applicable as well as his / her authorisation.", 15, pageline + 30);
    // if (signature) doc.addImage(signature, "JPEG", 25, pageline + 20, 35, 35, "final", "MEDIUM");
    // doc.text("____________________________", 20, pageline + 45);
    // doc.setFont("Helvetica", "italic", "bold");
    // doc.text("Signature of Main Insured Life", 25, pageline + 50);
    // doc.text(`Date: ${timeStamp}`, 72, pageline + 50);

    // to download pdf
    if (type === "download") {
      doc.save("Membership_Application.pdf");
      // view pdf
    } else if (type === "view") {
      window.open(doc.output("bloburl"));
    } else if (type === 'save') {
      var output = doc.output('datauristring')
      setBase64Data(output)
    }
  }

  const postToApi = async () => {
    let payload = {
      sourceData: "funeralApp",
      confirmsignYes: "Yes",
      addInfo,
      bankingDetailsDO,
      extendedFamily,
      representativeData,
      signature,
      base64Data,
      selfCoverData,
      ...profile,
      ...profileAddress,
      ...checkInfoData,
      ...totalCost,
      ...mainId,
      ...selfCoverData,
      ...beneficiary,
      ...employeeDetails,
      SourceSite: "funeral.shieldapp.co.za"
    };

    var config = {
      method: "post",
      url: "https://apiv2.msgl.ink/",
      headers: {
        "content-type": "application/json",
      },
      data: {
        ...payload,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        window.location.reload(true);
      });
  };

  useEffect(() => {
    if (approved) {
      postToApi();
    }
  }, [approved])

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Application, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">Document</h1>
        {!approved && (
          <div className="my-auto pb-5">
            <div className="text-center text-sm">
              {/* <p>
                You can Download your application now by clicking the button below
              </p> */}
              {/* <p>
                *NOTE - your application is not completed until you have clicked
                the "I Accept the Terms of this Agreement" button
              </p> */}
            </div>
            <div className="d-flex gap-4 mx-auto my-4 flex-column btn-wrapper">
              {/* <Button
                variant="primary"
                size="sm"
                className="fs-6 mb-2"
                onClick={(e) => pdfPayrollDeductionScript("view")}
              >
                View Pdf and Terms and Conditions
              </Button> */}
              <Button
                variant="primary"
                size="sm"
                className="fs-6"
                onClick={(e) => { setApproved(true); pdfPayrollDeductionScript('save'); setIsLoading(true) }}
              >
                Download your application
              </Button>
            </div>
          </div>
        )}
        {approved && (
          <div className="my-auto pb-5">
            <div className="text-center text-sm">
              <h6 className="mb-2 text-success">
                Thank you very much. We will be in contact shortly
              </h6>
              <div className="text-center text-sm mb-2">
                <p>While you wait you are welcome to download the agreements</p>
                <div className="d-flex gap-4 mx-auto my-4 flex-column btn-wrapper">
                  <Button
                    variant="primary"
                    size="sm"
                    className="fs-6"
                    onClick={(e) => {
                      pdfPayrollDeductionScript("download");
                    }}
                  >
                    Download Agreement
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Document;
